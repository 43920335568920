export const AnimationsInfiniteLine = () => import('../../components/Animations/InfiniteLine.vue' /* webpackChunkName: "components/animations-infinite-line" */).then(c => wrapFunctional(c.default || c))
export const ArticlesArticleDetail = () => import('../../components/Articles/ArticleDetail.vue' /* webpackChunkName: "components/articles-article-detail" */).then(c => wrapFunctional(c.default || c))
export const ArticlesArticleDetailCkeditor = () => import('../../components/Articles/ArticleDetailCkeditor.vue' /* webpackChunkName: "components/articles-article-detail-ckeditor" */).then(c => wrapFunctional(c.default || c))
export const ArticlesArticleDetailContent = () => import('../../components/Articles/ArticleDetailContent.vue' /* webpackChunkName: "components/articles-article-detail-content" */).then(c => wrapFunctional(c.default || c))
export const ArticlesArticleDetailImage = () => import('../../components/Articles/ArticleDetailImage.vue' /* webpackChunkName: "components/articles-article-detail-image" */).then(c => wrapFunctional(c.default || c))
export const ArticlesArticleDetailImages = () => import('../../components/Articles/ArticleDetailImages.vue' /* webpackChunkName: "components/articles-article-detail-images" */).then(c => wrapFunctional(c.default || c))
export const ArticlesArticleDetailList = () => import('../../components/Articles/ArticleDetailList.vue' /* webpackChunkName: "components/articles-article-detail-list" */).then(c => wrapFunctional(c.default || c))
export const ArticlesArticleDetailMenu = () => import('../../components/Articles/ArticleDetailMenu.vue' /* webpackChunkName: "components/articles-article-detail-menu" */).then(c => wrapFunctional(c.default || c))
export const ArticlesArticleDetailQuote = () => import('../../components/Articles/ArticleDetailQuote.vue' /* webpackChunkName: "components/articles-article-detail-quote" */).then(c => wrapFunctional(c.default || c))
export const ArticlesArticleDetailText = () => import('../../components/Articles/ArticleDetailText.vue' /* webpackChunkName: "components/articles-article-detail-text" */).then(c => wrapFunctional(c.default || c))
export const ArticlesArticleDetailVideo = () => import('../../components/Articles/ArticleDetailVideo.vue' /* webpackChunkName: "components/articles-article-detail-video" */).then(c => wrapFunctional(c.default || c))
export const ArticlesArticleShare = () => import('../../components/Articles/ArticleShare.vue' /* webpackChunkName: "components/articles-article-share" */).then(c => wrapFunctional(c.default || c))
export const ArticlesArticleTags = () => import('../../components/Articles/ArticleTags.vue' /* webpackChunkName: "components/articles-article-tags" */).then(c => wrapFunctional(c.default || c))
export const ArticlesSlider = () => import('../../components/Articles/ArticlesSlider.vue' /* webpackChunkName: "components/articles-slider" */).then(c => wrapFunctional(c.default || c))
export const AccountCompanyVacancyButton = () => import('../../components/Account/CompanyVacancyButton.vue' /* webpackChunkName: "components/account-company-vacancy-button" */).then(c => wrapFunctional(c.default || c))
export const AccountMenu = () => import('../../components/Account/Menu.vue' /* webpackChunkName: "components/account-menu" */).then(c => wrapFunctional(c.default || c))
export const BooksBookItem = () => import('../../components/Books/BookItem.vue' /* webpackChunkName: "components/books-book-item" */).then(c => wrapFunctional(c.default || c))
export const BannersApplicationPageCreationBanner = () => import('../../components/Banners/ApplicationPageCreationBanner.vue' /* webpackChunkName: "components/banners-application-page-creation-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersCareerMarathonBanner = () => import('../../components/Banners/CareerMarathonBanner.vue' /* webpackChunkName: "components/banners-career-marathon-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersCareerRoutesPagesBanner = () => import('../../components/Banners/CareerRoutesPagesBanner.vue' /* webpackChunkName: "components/banners-career-routes-pages-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersDigitalProfileAgroleader = () => import('../../components/Banners/DigitalProfileAgroleader.vue' /* webpackChunkName: "components/banners-digital-profile-agroleader" */).then(c => wrapFunctional(c.default || c))
export const BannersDigitalProfileBanner = () => import('../../components/Banners/DigitalProfileBanner.vue' /* webpackChunkName: "components/banners-digital-profile-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersEdwicaBanner = () => import('../../components/Banners/EdwicaBanner.vue' /* webpackChunkName: "components/banners-edwica-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersExecutiveSearchBanner = () => import('../../components/Banners/ExecutiveSearchBanner.vue' /* webpackChunkName: "components/banners-executive-search-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersFinancialBanner = () => import('../../components/Banners/FinancialBanner.vue' /* webpackChunkName: "components/banners-financial-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersFutureProfessionBanner = () => import('../../components/Banners/FutureProfessionBanner.vue' /* webpackChunkName: "components/banners-future-profession-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersHeadBanner = () => import('../../components/Banners/HeadBanner.vue' /* webpackChunkName: "components/banners-head-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersMainBanner = () => import('../../components/Banners/MainBanner.vue' /* webpackChunkName: "components/banners-main-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersMainTelegramBanner = () => import('../../components/Banners/MainTelegramBanner.vue' /* webpackChunkName: "components/banners-main-telegram-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersMarathonBanner = () => import('../../components/Banners/MarathonBanner.vue' /* webpackChunkName: "components/banners-marathon-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersMicroBanner = () => import('../../components/Banners/MicroBanner.vue' /* webpackChunkName: "components/banners-micro-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersOlympiadBanner = () => import('../../components/Banners/OlympiadBanner.vue' /* webpackChunkName: "components/banners-olympiad-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersPersonnelReserveBanner = () => import('../../components/Banners/PersonnelReserveBanner.vue' /* webpackChunkName: "components/banners-personnel-reserve-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersPrepareEGEBanner = () => import('../../components/Banners/PrepareEGEBanner.vue' /* webpackChunkName: "components/banners-prepare-e-g-e-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersProfessionalDevelopmenBanner = () => import('../../components/Banners/ProfessionalDevelopmenBanner.vue' /* webpackChunkName: "components/banners-professional-developmen-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersRSHBProductsStudentsBanner = () => import('../../components/Banners/RSHBProductsStudentsBanner.vue' /* webpackChunkName: "components/banners-r-s-h-b-products-students-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersStudentActivitiesBanner = () => import('../../components/Banners/StudentActivitiesBanner.vue' /* webpackChunkName: "components/banners-student-activities-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersSubscribeVacanciesBanner = () => import('../../components/Banners/SubscribeVacanciesBanner.vue' /* webpackChunkName: "components/banners-subscribe-vacancies-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersTopDigitalProfileBanner = () => import('../../components/Banners/TopDigitalProfileBanner.vue' /* webpackChunkName: "components/banners-top-digital-profile-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersVideoBanner = () => import('../../components/Banners/VideoBanner.vue' /* webpackChunkName: "components/banners-video-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersVseSvoeBanner = () => import('../../components/Banners/VseSvoeBanner.vue' /* webpackChunkName: "components/banners-vse-svoe-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersWomenAPKBannerMain = () => import('../../components/Banners/WomenAPKBannerMain.vue' /* webpackChunkName: "components/banners-women-a-p-k-banner-main" */).then(c => wrapFunctional(c.default || c))
export const BannersYouthCardBanner = () => import('../../components/Banners/YouthCardBanner.vue' /* webpackChunkName: "components/banners-youth-card-banner" */).then(c => wrapFunctional(c.default || c))
export const BottomMobileMenuBaseMobileBottomSearchMenu = () => import('../../components/BottomMobileMenu/BaseMobileBottomSearchMenu.vue' /* webpackChunkName: "components/bottom-mobile-menu-base-mobile-bottom-search-menu" */).then(c => wrapFunctional(c.default || c))
export const BottomMobileMenuBaseRabotaAgroBottomNavigationMenu = () => import('../../components/BottomMobileMenu/BaseRabotaAgroBottomNavigationMenu.vue' /* webpackChunkName: "components/bottom-mobile-menu-base-rabota-agro-bottom-navigation-menu" */).then(c => wrapFunctional(c.default || c))
export const BottomSheet = () => import('../../components/BottomMobileMenu/BottomSheet.vue' /* webpackChunkName: "components/bottom-sheet" */).then(c => wrapFunctional(c.default || c))
export const CareerNavigatorBarChart = () => import('../../components/CareerNavigator/CareerNavigatorBarChart.vue' /* webpackChunkName: "components/career-navigator-bar-chart" */).then(c => wrapFunctional(c.default || c))
export const CareerNavigatorLineChart = () => import('../../components/CareerNavigator/CareerNavigatorLineChart.vue' /* webpackChunkName: "components/career-navigator-line-chart" */).then(c => wrapFunctional(c.default || c))
export const CareerNavigatorPointsLine = () => import('../../components/CareerNavigator/CareerNavigatorPointsLine.vue' /* webpackChunkName: "components/career-navigator-points-line" */).then(c => wrapFunctional(c.default || c))
export const CareerNavigatorSelectionBlock = () => import('../../components/CareerNavigator/CareerNavigatorSelectionBlock.vue' /* webpackChunkName: "components/career-navigator-selection-block" */).then(c => wrapFunctional(c.default || c))
export const CarouselsPartnersCorousel = () => import('../../components/Carousels/PartnersCorousel.vue' /* webpackChunkName: "components/carousels-partners-corousel" */).then(c => wrapFunctional(c.default || c))
export const CarouselsProfessionInfoCarousel = () => import('../../components/Carousels/ProfessionInfoCarousel.vue' /* webpackChunkName: "components/carousels-profession-info-carousel" */).then(c => wrapFunctional(c.default || c))
export const ContainersBlockContainer = () => import('../../components/Containers/BlockContainer.vue' /* webpackChunkName: "components/containers-block-container" */).then(c => wrapFunctional(c.default || c))
export const ContainersGallerySwiperContainer = () => import('../../components/Containers/GallerySwiperContainer.vue' /* webpackChunkName: "components/containers-gallery-swiper-container" */).then(c => wrapFunctional(c.default || c))
export const ContainersLightboxContainer = () => import('../../components/Containers/LightboxContainer.vue' /* webpackChunkName: "components/containers-lightbox-container" */).then(c => wrapFunctional(c.default || c))
export const ContainersLightboxContainerBlock = () => import('../../components/Containers/LightboxContainerBlock.vue' /* webpackChunkName: "components/containers-lightbox-container-block" */).then(c => wrapFunctional(c.default || c))
export const ContainersMicroFrontendContainer = () => import('../../components/Containers/MicroFrontendContainer.vue' /* webpackChunkName: "components/containers-micro-frontend-container" */).then(c => wrapFunctional(c.default || c))
export const ContainersPageContainer = () => import('../../components/Containers/PageContainer.vue' /* webpackChunkName: "components/containers-page-container" */).then(c => wrapFunctional(c.default || c))
export const ContainersSwiperContainer = () => import('../../components/Containers/SwiperContainer.vue' /* webpackChunkName: "components/containers-swiper-container" */).then(c => wrapFunctional(c.default || c))
export const ContainersUniversitySwiperContainer = () => import('../../components/Containers/UniversitySwiperContainer.vue' /* webpackChunkName: "components/containers-university-swiper-container" */).then(c => wrapFunctional(c.default || c))
export const Cookie = () => import('../../components/Cookie/Cookie.vue' /* webpackChunkName: "components/cookie" */).then(c => wrapFunctional(c.default || c))
export const CourseCoursesSlider = () => import('../../components/Course/CoursesSlider.vue' /* webpackChunkName: "components/course-courses-slider" */).then(c => wrapFunctional(c.default || c))
export const CreditBanner = () => import('../../components/CreditBanner/CreditBanner.vue' /* webpackChunkName: "components/credit-banner" */).then(c => wrapFunctional(c.default || c))
export const EmployerAbout = () => import('../../components/Employer/About.vue' /* webpackChunkName: "components/employer-about" */).then(c => wrapFunctional(c.default || c))
export const EmployerCommandParts = () => import('../../components/Employer/CommandParts.vue' /* webpackChunkName: "components/employer-command-parts" */).then(c => wrapFunctional(c.default || c))
export const EmployerCard = () => import('../../components/Employer/EmployerCard.vue' /* webpackChunkName: "components/employer-card" */).then(c => wrapFunctional(c.default || c))
export const EmployerHistories = () => import('../../components/Employer/Histories.vue' /* webpackChunkName: "components/employer-histories" */).then(c => wrapFunctional(c.default || c))
export const EmployerMap = () => import('../../components/Employer/Map.vue' /* webpackChunkName: "components/employer-map" */).then(c => wrapFunctional(c.default || c))
export const EmployerNews = () => import('../../components/Employer/News.vue' /* webpackChunkName: "components/employer-news" */).then(c => wrapFunctional(c.default || c))
export const EmployerOffers = () => import('../../components/Employer/Offers.vue' /* webpackChunkName: "components/employer-offers" */).then(c => wrapFunctional(c.default || c))
export const EmployerRequirements = () => import('../../components/Employer/Requirements.vue' /* webpackChunkName: "components/employer-requirements" */).then(c => wrapFunctional(c.default || c))
export const EmployerSlides = () => import('../../components/Employer/Slides.vue' /* webpackChunkName: "components/employer-slides" */).then(c => wrapFunctional(c.default || c))
export const EmployerTasks = () => import('../../components/Employer/Tasks.vue' /* webpackChunkName: "components/employer-tasks" */).then(c => wrapFunctional(c.default || c))
export const EmployerTextNumbers = () => import('../../components/Employer/TextNumbers.vue' /* webpackChunkName: "components/employer-text-numbers" */).then(c => wrapFunctional(c.default || c))
export const EmployerVacancies = () => import('../../components/Employer/Vacancies.vue' /* webpackChunkName: "components/employer-vacancies" */).then(c => wrapFunctional(c.default || c))
export const EmployerVideo = () => import('../../components/Employer/Video.vue' /* webpackChunkName: "components/employer-video" */).then(c => wrapFunctional(c.default || c))
export const EmployerWarranties = () => import('../../components/Employer/Warranties.vue' /* webpackChunkName: "components/employer-warranties" */).then(c => wrapFunctional(c.default || c))
export const ErrorNotFound = () => import('../../components/Error/NotFound.vue' /* webpackChunkName: "components/error-not-found" */).then(c => wrapFunctional(c.default || c))
export const ErrorNotFoundVacancy = () => import('../../components/Error/NotFoundVacancy.vue' /* webpackChunkName: "components/error-not-found-vacancy" */).then(c => wrapFunctional(c.default || c))
export const FiltersBaseFiltersWrapper = () => import('../../components/Filters/BaseFiltersWrapper.vue' /* webpackChunkName: "components/filters-base-filters-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FiltersCheckboxFilter = () => import('../../components/Filters/CheckboxFilter.vue' /* webpackChunkName: "components/filters-checkbox-filter" */).then(c => wrapFunctional(c.default || c))
export const FiltersClearButton = () => import('../../components/Filters/ClearButton.vue' /* webpackChunkName: "components/filters-clear-button" */).then(c => wrapFunctional(c.default || c))
export const FiltersDropDownMulti = () => import('../../components/Filters/DropDownMulti.vue' /* webpackChunkName: "components/filters-drop-down-multi" */).then(c => wrapFunctional(c.default || c))
export const FiltersMultiSelect = () => import('../../components/Filters/MultiSelect.vue' /* webpackChunkName: "components/filters-multi-select" */).then(c => wrapFunctional(c.default || c))
export const FiltersRangeSlider = () => import('../../components/Filters/RangeSlider.vue' /* webpackChunkName: "components/filters-range-slider" */).then(c => wrapFunctional(c.default || c))
export const FiltersSearchInput = () => import('../../components/Filters/SearchInput.vue' /* webpackChunkName: "components/filters-search-input" */).then(c => wrapFunctional(c.default || c))
export const FiltersSelectedValues = () => import('../../components/Filters/SelectedValues.vue' /* webpackChunkName: "components/filters-selected-values" */).then(c => wrapFunctional(c.default || c))
export const FiltersTagButton = () => import('../../components/Filters/TagButton.vue' /* webpackChunkName: "components/filters-tag-button" */).then(c => wrapFunctional(c.default || c))
export const FiltersToogleBlock = () => import('../../components/Filters/ToogleBlock.vue' /* webpackChunkName: "components/filters-toogle-block" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const HeaderBottomMenu = () => import('../../components/Header/BottomMenu.vue' /* webpackChunkName: "components/header-bottom-menu" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/Header/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const HeaderEcosystem = () => import('../../components/Header/HeaderEcosystem.vue' /* webpackChunkName: "components/header-ecosystem" */).then(c => wrapFunctional(c.default || c))
export const HeaderEcosystemMobile = () => import('../../components/Header/HeaderEcosystemMobile.vue' /* webpackChunkName: "components/header-ecosystem-mobile" */).then(c => wrapFunctional(c.default || c))
export const HeaderMobileSidebarMenu = () => import('../../components/Header/MobileSidebarMenu.vue' /* webpackChunkName: "components/header-mobile-sidebar-menu" */).then(c => wrapFunctional(c.default || c))
export const HeaderTopMenu = () => import('../../components/Header/TopMenu.vue' /* webpackChunkName: "components/header-top-menu" */).then(c => wrapFunctional(c.default || c))
export const InfoBlock = () => import('../../components/InfoBlocks/InfoBlock.vue' /* webpackChunkName: "components/info-block" */).then(c => wrapFunctional(c.default || c))
export const LightboxButtonCuponWrap = () => import('../../components/Lightbox/LightboxButtonCuponWrap.vue' /* webpackChunkName: "components/lightbox-button-cupon-wrap" */).then(c => wrapFunctional(c.default || c))
export const LightboxButtonWrap = () => import('../../components/Lightbox/LightboxButtonWrap.vue' /* webpackChunkName: "components/lightbox-button-wrap" */).then(c => wrapFunctional(c.default || c))
export const LightboxCardsList = () => import('../../components/Lightbox/LightboxCardsList.vue' /* webpackChunkName: "components/lightbox-cards-list" */).then(c => wrapFunctional(c.default || c))
export const LightboxDescription = () => import('../../components/Lightbox/LightboxDescription.vue' /* webpackChunkName: "components/lightbox-description" */).then(c => wrapFunctional(c.default || c))
export const LightboxDirectionList = () => import('../../components/Lightbox/LightboxDirectionList.vue' /* webpackChunkName: "components/lightbox-direction-list" */).then(c => wrapFunctional(c.default || c))
export const LightboxEventInfoList = () => import('../../components/Lightbox/LightboxEventInfoList.vue' /* webpackChunkName: "components/lightbox-event-info-list" */).then(c => wrapFunctional(c.default || c))
export const LightboxHeader = () => import('../../components/Lightbox/LightboxHeader.vue' /* webpackChunkName: "components/lightbox-header" */).then(c => wrapFunctional(c.default || c))
export const LightboxHeaderEvent = () => import('../../components/Lightbox/LightboxHeaderEvent.vue' /* webpackChunkName: "components/lightbox-header-event" */).then(c => wrapFunctional(c.default || c))
export const LightboxInfoList = () => import('../../components/Lightbox/LightboxInfoList.vue' /* webpackChunkName: "components/lightbox-info-list" */).then(c => wrapFunctional(c.default || c))
export const LightboxPrice = () => import('../../components/Lightbox/LightboxPrice.vue' /* webpackChunkName: "components/lightbox-price" */).then(c => wrapFunctional(c.default || c))
export const LightboxSuitItem = () => import('../../components/Lightbox/LightboxSuitItem.vue' /* webpackChunkName: "components/lightbox-suit-item" */).then(c => wrapFunctional(c.default || c))
export const LightboxSuitableList = () => import('../../components/Lightbox/LightboxSuitableList.vue' /* webpackChunkName: "components/lightbox-suitable-list" */).then(c => wrapFunctional(c.default || c))
export const LightboxTitle = () => import('../../components/Lightbox/LightboxTitle.vue' /* webpackChunkName: "components/lightbox-title" */).then(c => wrapFunctional(c.default || c))
export const MiscActionBlock = () => import('../../components/Misc/ActionBlock.vue' /* webpackChunkName: "components/misc-action-block" */).then(c => wrapFunctional(c.default || c))
export const MiscButtonFilter = () => import('../../components/Misc/ButtonFilter.vue' /* webpackChunkName: "components/misc-button-filter" */).then(c => wrapFunctional(c.default || c))
export const MiscCardCount = () => import('../../components/Misc/CardCount.vue' /* webpackChunkName: "components/misc-card-count" */).then(c => wrapFunctional(c.default || c))
export const MiscEducationalInstitutionFilters = () => import('../../components/Misc/EducationalInstitutionFilters.vue' /* webpackChunkName: "components/misc-educational-institution-filters" */).then(c => wrapFunctional(c.default || c))
export const MiscFilterContainer = () => import('../../components/Misc/FilterContainer.vue' /* webpackChunkName: "components/misc-filter-container" */).then(c => wrapFunctional(c.default || c))
export const MiscGlobalSearch = () => import('../../components/Misc/GlobalSearch.vue' /* webpackChunkName: "components/misc-global-search" */).then(c => wrapFunctional(c.default || c))
export const MiscInlineMenu = () => import('../../components/Misc/InlineMenu.vue' /* webpackChunkName: "components/misc-inline-menu" */).then(c => wrapFunctional(c.default || c))
export const MiscInstructionBlock = () => import('../../components/Misc/InstructionBlock.vue' /* webpackChunkName: "components/misc-instruction-block" */).then(c => wrapFunctional(c.default || c))
export const MiscMapBlock = () => import('../../components/Misc/MapBlock.vue' /* webpackChunkName: "components/misc-map-block" */).then(c => wrapFunctional(c.default || c))
export const MiscMapContainer = () => import('../../components/Misc/MapContainer.vue' /* webpackChunkName: "components/misc-map-container" */).then(c => wrapFunctional(c.default || c))
export const MiscModalContainer = () => import('../../components/Misc/ModalContainer.vue' /* webpackChunkName: "components/misc-modal-container" */).then(c => wrapFunctional(c.default || c))
export const MiscModalRegionLocationContainer = () => import('../../components/Misc/ModalRegionLocationContainer.vue' /* webpackChunkName: "components/misc-modal-region-location-container" */).then(c => wrapFunctional(c.default || c))
export const MiscNotFoundBlock = () => import('../../components/Misc/NotFoundBlock.vue' /* webpackChunkName: "components/misc-not-found-block" */).then(c => wrapFunctional(c.default || c))
export const MiscRangeSlider = () => import('../../components/Misc/RangeSlider.vue' /* webpackChunkName: "components/misc-range-slider" */).then(c => wrapFunctional(c.default || c))
export const MiscSearchComponent = () => import('../../components/Misc/SearchComponent.vue' /* webpackChunkName: "components/misc-search-component" */).then(c => wrapFunctional(c.default || c))
export const MiscTagCloud = () => import('../../components/Misc/TagCloud.vue' /* webpackChunkName: "components/misc-tag-cloud" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/Modal/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const ModalApplicationPageCreation = () => import('../../components/Modal/ModalApplicationPageCreation.vue' /* webpackChunkName: "components/modal-application-page-creation" */).then(c => wrapFunctional(c.default || c))
export const ModalAuth = () => import('../../components/Modal/ModalAuth.vue' /* webpackChunkName: "components/modal-auth" */).then(c => wrapFunctional(c.default || c))
export const ModalClose = () => import('../../components/Modal/ModalClose.vue' /* webpackChunkName: "components/modal-close" */).then(c => wrapFunctional(c.default || c))
export const ModalConfirm = () => import('../../components/Modal/ModalConfirm.vue' /* webpackChunkName: "components/modal-confirm" */).then(c => wrapFunctional(c.default || c))
export const ModalHeaderLogo = () => import('../../components/Modal/ModalHeaderLogo.vue' /* webpackChunkName: "components/modal-header-logo" */).then(c => wrapFunctional(c.default || c))
export const ModalSubscribeVacanciesForm = () => import('../../components/Modal/ModalSubscribeVacanciesForm.vue' /* webpackChunkName: "components/modal-subscribe-vacancies-form" */).then(c => wrapFunctional(c.default || c))
export const ModalSuccess = () => import('../../components/Modal/ModalSuccess.vue' /* webpackChunkName: "components/modal-success" */).then(c => wrapFunctional(c.default || c))
export const NewsCardSmall = () => import('../../components/News/NewsCardSmall.vue' /* webpackChunkName: "components/news-card-small" */).then(c => wrapFunctional(c.default || c))
export const NotificationsBaseYavagroToast = () => import('../../components/Notifications/BaseYavagroToast.vue' /* webpackChunkName: "components/notifications-base-yavagro-toast" */).then(c => wrapFunctional(c.default || c))
export const NotificationsBaseYavagroToastOverlay = () => import('../../components/Notifications/BaseYavagroToastOverlay.vue' /* webpackChunkName: "components/notifications-base-yavagro-toast-overlay" */).then(c => wrapFunctional(c.default || c))
export const NotificationsBaseYavagroToastWrapper = () => import('../../components/Notifications/BaseYavagroToastWrapper.vue' /* webpackChunkName: "components/notifications-base-yavagro-toast-wrapper" */).then(c => wrapFunctional(c.default || c))
export const OurServices = () => import('../../components/OurServices/OurServices.vue' /* webpackChunkName: "components/our-services" */).then(c => wrapFunctional(c.default || c))
export const PotrebCredit = () => import('../../components/PotrebCredit/index.vue' /* webpackChunkName: "components/potreb-credit" */).then(c => wrapFunctional(c.default || c))
export const RegionLocation = () => import('../../components/RegionLocation/RegionLocation.vue' /* webpackChunkName: "components/region-location" */).then(c => wrapFunctional(c.default || c))
export const SvoiIcon = () => import('../../components/SvoiUI/SvoiIcon.vue' /* webpackChunkName: "components/svoi-icon" */).then(c => wrapFunctional(c.default || c))
export const TestingFinancialLiteracyTestResult = () => import('../../components/Testing/FinancialLiteracyTestResult.vue' /* webpackChunkName: "components/testing-financial-literacy-test-result" */).then(c => wrapFunctional(c.default || c))
export const TestingPersonalityCountsDiagram = () => import('../../components/Testing/PersonalityCountsDiagram.vue' /* webpackChunkName: "components/testing-personality-counts-diagram" */).then(c => wrapFunctional(c.default || c))
export const TestingRecommendation = () => import('../../components/Testing/Recommendation.vue' /* webpackChunkName: "components/testing-recommendation" */).then(c => wrapFunctional(c.default || c))
export const TestingRecommendationCourses = () => import('../../components/Testing/RecommendationCourses.vue' /* webpackChunkName: "components/testing-recommendation-courses" */).then(c => wrapFunctional(c.default || c))
export const TestingRecommendationDirections = () => import('../../components/Testing/RecommendationDirections.vue' /* webpackChunkName: "components/testing-recommendation-directions" */).then(c => wrapFunctional(c.default || c))
export const TestingRecommendationNews = () => import('../../components/Testing/RecommendationNews.vue' /* webpackChunkName: "components/testing-recommendation-news" */).then(c => wrapFunctional(c.default || c))
export const TestingRecommendationProfessions = () => import('../../components/Testing/RecommendationProfessions.vue' /* webpackChunkName: "components/testing-recommendation-professions" */).then(c => wrapFunctional(c.default || c))
export const TestingRecommendationVacancies = () => import('../../components/Testing/RecommendationVacancies.vue' /* webpackChunkName: "components/testing-recommendation-vacancies" */).then(c => wrapFunctional(c.default || c))
export const UIAddButton = () => import('../../components/UI/AddButton.vue' /* webpackChunkName: "components/u-i-add-button" */).then(c => wrapFunctional(c.default || c))
export const UIAppLazyImage = () => import('../../components/UI/AppLazyImage.vue' /* webpackChunkName: "components/u-i-app-lazy-image" */).then(c => wrapFunctional(c.default || c))
export const UIBaseFilters = () => import('../../components/UI/BaseFilters.vue' /* webpackChunkName: "components/u-i-base-filters" */).then(c => wrapFunctional(c.default || c))
export const UIBaseInput = () => import('../../components/UI/BaseInput.vue' /* webpackChunkName: "components/u-i-base-input" */).then(c => wrapFunctional(c.default || c))
export const UIBaseInsuranceCertificateInput = () => import('../../components/UI/BaseInsuranceCertificateInput.vue' /* webpackChunkName: "components/u-i-base-insurance-certificate-input" */).then(c => wrapFunctional(c.default || c))
export const UIBaseRecommendationCard = () => import('../../components/UI/BaseRecommendationCard.vue' /* webpackChunkName: "components/u-i-base-recommendation-card" */).then(c => wrapFunctional(c.default || c))
export const UIBaseSearch = () => import('../../components/UI/BaseSearch.vue' /* webpackChunkName: "components/u-i-base-search" */).then(c => wrapFunctional(c.default || c))
export const UIBaseSort = () => import('../../components/UI/BaseSort.vue' /* webpackChunkName: "components/u-i-base-sort" */).then(c => wrapFunctional(c.default || c))
export const UIBaseYavagroCourseCard = () => import('../../components/UI/BaseYavagroCourseCard.vue' /* webpackChunkName: "components/u-i-base-yavagro-course-card" */).then(c => wrapFunctional(c.default || c))
export const UIBaseYavagroDirectionCard = () => import('../../components/UI/BaseYavagroDirectionCard.vue' /* webpackChunkName: "components/u-i-base-yavagro-direction-card" */).then(c => wrapFunctional(c.default || c))
export const UIBaseYavagroFutureProfCard = () => import('../../components/UI/BaseYavagroFutureProfCard.vue' /* webpackChunkName: "components/u-i-base-yavagro-future-prof-card" */).then(c => wrapFunctional(c.default || c))
export const UIBaseYavagroHrefCard = () => import('../../components/UI/BaseYavagroHrefCard.vue' /* webpackChunkName: "components/u-i-base-yavagro-href-card" */).then(c => wrapFunctional(c.default || c))
export const UIBaseYavagroInfiniteLine = () => import('../../components/UI/BaseYavagroInfiniteLine.vue' /* webpackChunkName: "components/u-i-base-yavagro-infinite-line" */).then(c => wrapFunctional(c.default || c))
export const UIBaseYavagroInput = () => import('../../components/UI/BaseYavagroInput.vue' /* webpackChunkName: "components/u-i-base-yavagro-input" */).then(c => wrapFunctional(c.default || c))
export const UIBaseYavagroLabel = () => import('../../components/UI/BaseYavagroLabel.vue' /* webpackChunkName: "components/u-i-base-yavagro-label" */).then(c => wrapFunctional(c.default || c))
export const UIBaseYavagroNewCard = () => import('../../components/UI/BaseYavagroNewCard.vue' /* webpackChunkName: "components/u-i-base-yavagro-new-card" */).then(c => wrapFunctional(c.default || c))
export const UIBaseYavagroUniversityInfo = () => import('../../components/UI/BaseYavagroUniversityInfo.vue' /* webpackChunkName: "components/u-i-base-yavagro-university-info" */).then(c => wrapFunctional(c.default || c))
export const UIBaseYavagroVideo = () => import('../../components/UI/BaseYavagroVideo.vue' /* webpackChunkName: "components/u-i-base-yavagro-video" */).then(c => wrapFunctional(c.default || c))
export const UIBaseYavagroWorkCard = () => import('../../components/UI/BaseYavagroWorkCard.vue' /* webpackChunkName: "components/u-i-base-yavagro-work-card" */).then(c => wrapFunctional(c.default || c))
export const UIBreadCrumbs = () => import('../../components/UI/BreadCrumbs.vue' /* webpackChunkName: "components/u-i-bread-crumbs" */).then(c => wrapFunctional(c.default || c))
export const UIButtonLinkYavagro = () => import('../../components/UI/ButtonLinkYavagro.vue' /* webpackChunkName: "components/u-i-button-link-yavagro" */).then(c => wrapFunctional(c.default || c))
export const UIButtonYavagro = () => import('../../components/UI/ButtonYavagro.vue' /* webpackChunkName: "components/u-i-button-yavagro" */).then(c => wrapFunctional(c.default || c))
export const UICheckboxFilter = () => import('../../components/UI/CheckboxFilter.vue' /* webpackChunkName: "components/u-i-checkbox-filter" */).then(c => wrapFunctional(c.default || c))
export const UICheckboxYavagro = () => import('../../components/UI/CheckboxYavagro.vue' /* webpackChunkName: "components/u-i-checkbox-yavagro" */).then(c => wrapFunctional(c.default || c))
export const UIDropdownListYavagro = () => import('../../components/UI/DropdownListYavagro.vue' /* webpackChunkName: "components/u-i-dropdown-list-yavagro" */).then(c => wrapFunctional(c.default || c))
export const UIGeolocation = () => import('../../components/UI/Geolocation.vue' /* webpackChunkName: "components/u-i-geolocation" */).then(c => wrapFunctional(c.default || c))
export const UIInputPhoneYavagro = () => import('../../components/UI/InputPhoneYavagro.vue' /* webpackChunkName: "components/u-i-input-phone-yavagro" */).then(c => wrapFunctional(c.default || c))
export const UIInputYavagro = () => import('../../components/UI/InputYavagro.vue' /* webpackChunkName: "components/u-i-input-yavagro" */).then(c => wrapFunctional(c.default || c))
export const UIMultiSelect = () => import('../../components/UI/MultiSelect.vue' /* webpackChunkName: "components/u-i-multi-select" */).then(c => wrapFunctional(c.default || c))
export const UIPaginationWrapper = () => import('../../components/UI/PaginationWrapper.vue' /* webpackChunkName: "components/u-i-pagination-wrapper" */).then(c => wrapFunctional(c.default || c))
export const UIRabotaTab = () => import('../../components/UI/RabotaTab.vue' /* webpackChunkName: "components/u-i-rabota-tab" */).then(c => wrapFunctional(c.default || c))
export const UIRabotaTabs = () => import('../../components/UI/RabotaTabs.vue' /* webpackChunkName: "components/u-i-rabota-tabs" */).then(c => wrapFunctional(c.default || c))
export const UIRadioButton = () => import('../../components/UI/RadioButton.vue' /* webpackChunkName: "components/u-i-radio-button" */).then(c => wrapFunctional(c.default || c))
export const UIRoundButton = () => import('../../components/UI/RoundButton.vue' /* webpackChunkName: "components/u-i-round-button" */).then(c => wrapFunctional(c.default || c))
export const UISearchWithButton = () => import('../../components/UI/SearchWithButton.vue' /* webpackChunkName: "components/u-i-search-with-button" */).then(c => wrapFunctional(c.default || c))
export const UISelectYavagro = () => import('../../components/UI/SelectYavagro.vue' /* webpackChunkName: "components/u-i-select-yavagro" */).then(c => wrapFunctional(c.default || c))
export const UISkeletonBase = () => import('../../components/UI/SkeletonBase.vue' /* webpackChunkName: "components/u-i-skeleton-base" */).then(c => wrapFunctional(c.default || c))
export const UISkeletonFilters = () => import('../../components/UI/SkeletonFilters.vue' /* webpackChunkName: "components/u-i-skeleton-filters" */).then(c => wrapFunctional(c.default || c))
export const UIUploadFileButton = () => import('../../components/UI/UploadFileButton.vue' /* webpackChunkName: "components/u-i-upload-file-button" */).then(c => wrapFunctional(c.default || c))
export const UIUploadFileDisplay = () => import('../../components/UI/UploadFileDisplay.vue' /* webpackChunkName: "components/u-i-upload-file-display" */).then(c => wrapFunctional(c.default || c))
export const UIYavagroProfessionCard = () => import('../../components/UI/YavagroProfessionCard.vue' /* webpackChunkName: "components/u-i-yavagro-profession-card" */).then(c => wrapFunctional(c.default || c))
export const UIYavagroRoundTag = () => import('../../components/UI/YavagroRoundTag.vue' /* webpackChunkName: "components/u-i-yavagro-round-tag" */).then(c => wrapFunctional(c.default || c))
export const UIYavagroSearchInput = () => import('../../components/UI/YavagroSearchInput.vue' /* webpackChunkName: "components/u-i-yavagro-search-input" */).then(c => wrapFunctional(c.default || c))
export const UIYavagroTab = () => import('../../components/UI/YavagroTab.vue' /* webpackChunkName: "components/u-i-yavagro-tab" */).then(c => wrapFunctional(c.default || c))
export const UIYavagroTabs = () => import('../../components/UI/YavagroTabs.vue' /* webpackChunkName: "components/u-i-yavagro-tabs" */).then(c => wrapFunctional(c.default || c))
export const UIYavagroVideo = () => import('../../components/UI/YavagroVideo.vue' /* webpackChunkName: "components/u-i-yavagro-video" */).then(c => wrapFunctional(c.default || c))
export const UIYavagroVideoVk = () => import('../../components/UI/YavagroVideoVk.vue' /* webpackChunkName: "components/u-i-yavagro-video-vk" */).then(c => wrapFunctional(c.default || c))
export const VacancyCardCompany = () => import('../../components/Vacancy/CardCompany.vue' /* webpackChunkName: "components/vacancy-card-company" */).then(c => wrapFunctional(c.default || c))
export const VacancyCardHeader = () => import('../../components/Vacancy/CardHeader.vue' /* webpackChunkName: "components/vacancy-card-header" */).then(c => wrapFunctional(c.default || c))
export const VacancyCardInformation = () => import('../../components/Vacancy/CardInformation.vue' /* webpackChunkName: "components/vacancy-card-information" */).then(c => wrapFunctional(c.default || c))
export const VacancyCardSimilar = () => import('../../components/Vacancy/CardSimilar.vue' /* webpackChunkName: "components/vacancy-card-similar" */).then(c => wrapFunctional(c.default || c))
export const VacancyRoundTag = () => import('../../components/Vacancy/RoundTag.vue' /* webpackChunkName: "components/vacancy-round-tag" */).then(c => wrapFunctional(c.default || c))
export const VideoList = () => import('../../components/Video/List.vue' /* webpackChunkName: "components/video-list" */).then(c => wrapFunctional(c.default || c))
export const YandexMap = () => import('../../components/Yandex/Map.vue' /* webpackChunkName: "components/yandex-map" */).then(c => wrapFunctional(c.default || c))
export const YandexShare = () => import('../../components/Yandex/Share.vue' /* webpackChunkName: "components/yandex-share" */).then(c => wrapFunctional(c.default || c))
export const AccountDigitalProfileAgroleaderForm = () => import('../../components/Account/DigitalProfile/AgroleaderForm.vue' /* webpackChunkName: "components/account-digital-profile-agroleader-form" */).then(c => wrapFunctional(c.default || c))
export const AccountDigitalProfileAmbassadorsClubForm = () => import('../../components/Account/DigitalProfile/AmbassadorsClubForm.vue' /* webpackChunkName: "components/account-digital-profile-ambassadors-club-form" */).then(c => wrapFunctional(c.default || c))
export const AccountDigitalProfilePersonalDataForm = () => import('../../components/Account/DigitalProfile/PersonalDataForm.vue' /* webpackChunkName: "components/account-digital-profile-personal-data-form" */).then(c => wrapFunctional(c.default || c))
export const AccountDigitalProfileView = () => import('../../components/Account/DigitalProfile/ProfileView.vue' /* webpackChunkName: "components/account-digital-profile-view" */).then(c => wrapFunctional(c.default || c))
export const AccountDigitalProfileProjectsForm = () => import('../../components/Account/DigitalProfile/ProjectsForm.vue' /* webpackChunkName: "components/account-digital-profile-projects-form" */).then(c => wrapFunctional(c.default || c))
export const AccountDigitalProfileStudentDataForm = () => import('../../components/Account/DigitalProfile/StudentDataForm.vue' /* webpackChunkName: "components/account-digital-profile-student-data-form" */).then(c => wrapFunctional(c.default || c))
export const AccountPublishModalAddGroups = () => import('../../components/Account/Publish/ModalAddGroups.vue' /* webpackChunkName: "components/account-publish-modal-add-groups" */).then(c => wrapFunctional(c.default || c))
export const AccountPublishModalAuthVk = () => import('../../components/Account/Publish/ModalAuthVk.vue' /* webpackChunkName: "components/account-publish-modal-auth-vk" */).then(c => wrapFunctional(c.default || c))
export const AccountPublishSettings = () => import('../../components/Account/Publish/Settings.vue' /* webpackChunkName: "components/account-publish-settings" */).then(c => wrapFunctional(c.default || c))
export const AccountPublishSettingsGroup = () => import('../../components/Account/Publish/SettingsGroup.vue' /* webpackChunkName: "components/account-publish-settings-group" */).then(c => wrapFunctional(c.default || c))
export const AccountResumeBannerSubscription = () => import('../../components/Account/Resume/BannerSubscription.vue' /* webpackChunkName: "components/account-resume-banner-subscription" */).then(c => wrapFunctional(c.default || c))
export const AccountResumeEdwicaAccountBanner = () => import('../../components/Account/Resume/EdwicaAccountBanner.vue' /* webpackChunkName: "components/account-resume-edwica-account-banner" */).then(c => wrapFunctional(c.default || c))
export const AccountResumesSubscriptionsDeleteModal = () => import('../../components/Account/ResumesSubscriptions/ResumesSubscriptionsDeleteModal.vue' /* webpackChunkName: "components/account-resumes-subscriptions-delete-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountResumesSubscriptionsEditFilters = () => import('../../components/Account/ResumesSubscriptions/ResumesSubscriptionsEditFilters.vue' /* webpackChunkName: "components/account-resumes-subscriptions-edit-filters" */).then(c => wrapFunctional(c.default || c))
export const AccountResumesSubscriptionsNotFound = () => import('../../components/Account/ResumesSubscriptions/ResumesSubscriptionsNotFound.vue' /* webpackChunkName: "components/account-resumes-subscriptions-not-found" */).then(c => wrapFunctional(c.default || c))
export const AccountUniversityStatisticsDigitalProfileStatistics = () => import('../../components/Account/UniversityStatistics/DigitalProfileStatistics.vue' /* webpackChunkName: "components/account-university-statistics-digital-profile-statistics" */).then(c => wrapFunctional(c.default || c))
export const CardsArticlesCard = () => import('../../components/Cards/ArticlesCards/ArticlesCard.vue' /* webpackChunkName: "components/cards-articles-card" */).then(c => wrapFunctional(c.default || c))
export const CardsBookCard = () => import('../../components/Cards/BookCards/BookCard.vue' /* webpackChunkName: "components/cards-book-card" */).then(c => wrapFunctional(c.default || c))
export const CardsBookCardsList = () => import('../../components/Cards/BookCards/BookCardsList.vue' /* webpackChunkName: "components/cards-book-cards-list" */).then(c => wrapFunctional(c.default || c))
export const CardsCareerRoutesCard = () => import('../../components/Cards/CareerRoutes/CareerRoutesCard.vue' /* webpackChunkName: "components/cards-career-routes-card" */).then(c => wrapFunctional(c.default || c))
export const CardsCollegeProgramsCard = () => import('../../components/Cards/CollegeProgramsCard/CollegeProgramsCard.vue' /* webpackChunkName: "components/cards-college-programs-card" */).then(c => wrapFunctional(c.default || c))
export const CardsCompetitionsCardsCompetitionInfoCard = () => import('../../components/Cards/CompetitionsCards/CompetitionInfoCard.vue' /* webpackChunkName: "components/cards-competitions-cards-competition-info-card" */).then(c => wrapFunctional(c.default || c))
export const CardsConferenceCardItem = () => import('../../components/Cards/ConferenceCards/ConferenceCardItem.vue' /* webpackChunkName: "components/cards-conference-card-item" */).then(c => wrapFunctional(c.default || c))
export const CardsConferenceCardsList = () => import('../../components/Cards/ConferenceCards/ConferenceCardsList.vue' /* webpackChunkName: "components/cards-conference-cards-list" */).then(c => wrapFunctional(c.default || c))
export const CardsCoursesCard = () => import('../../components/Cards/CoursesCards/CoursesCard.vue' /* webpackChunkName: "components/cards-courses-card" */).then(c => wrapFunctional(c.default || c))
export const CardsCoursesCardsList = () => import('../../components/Cards/CoursesCards/CoursesCardsList.vue' /* webpackChunkName: "components/cards-courses-cards-list" */).then(c => wrapFunctional(c.default || c))
export const CardsDirectionCard = () => import('../../components/Cards/DirectionCards/DirectionCard.vue' /* webpackChunkName: "components/cards-direction-card" */).then(c => wrapFunctional(c.default || c))
export const CardsDirectionCardsList = () => import('../../components/Cards/DirectionCards/DirectionCardsList.vue' /* webpackChunkName: "components/cards-direction-cards-list" */).then(c => wrapFunctional(c.default || c))
export const CardsEmployerCard = () => import('../../components/Cards/EmployerCards/EmployerCard.vue' /* webpackChunkName: "components/cards-employer-card" */).then(c => wrapFunctional(c.default || c))
export const CardsEmployerCardsList = () => import('../../components/Cards/EmployerCards/EmployerCardsList.vue' /* webpackChunkName: "components/cards-employer-cards-list" */).then(c => wrapFunctional(c.default || c))
export const CardsEmployerNewsCard = () => import('../../components/Cards/EmployerNewsCards/NewsCard.vue' /* webpackChunkName: "components/cards-employer-news-card" */).then(c => wrapFunctional(c.default || c))
export const CardsEmployerNewsCardsList = () => import('../../components/Cards/EmployerNewsCards/NewsCardsList.vue' /* webpackChunkName: "components/cards-employer-news-cards-list" */).then(c => wrapFunctional(c.default || c))
export const CardsEventCard = () => import('../../components/Cards/EventCards/EventCard.vue' /* webpackChunkName: "components/cards-event-card" */).then(c => wrapFunctional(c.default || c))
export const CardsEventCardsList = () => import('../../components/Cards/EventCards/EventCardsList.vue' /* webpackChunkName: "components/cards-event-cards-list" */).then(c => wrapFunctional(c.default || c))
export const CardsEventSliderCard = () => import('../../components/Cards/EventCards/EventSliderCard.vue' /* webpackChunkName: "components/cards-event-slider-card" */).then(c => wrapFunctional(c.default || c))
export const CardsFutureProfCard = () => import('../../components/Cards/FutureProfCards/FutureProfCard.vue' /* webpackChunkName: "components/cards-future-prof-card" */).then(c => wrapFunctional(c.default || c))
export const CardsFutureProfCardsGrid = () => import('../../components/Cards/FutureProfCards/FutureProfCardsGrid.vue' /* webpackChunkName: "components/cards-future-prof-cards-grid" */).then(c => wrapFunctional(c.default || c))
export const CardsFutureProfCardsList = () => import('../../components/Cards/FutureProfCards/FutureProfCardsList.vue' /* webpackChunkName: "components/cards-future-prof-cards-list" */).then(c => wrapFunctional(c.default || c))
export const CardsHorizontalInfoCard = () => import('../../components/Cards/HorizontalInfoCards/HorizontalInfoCard.vue' /* webpackChunkName: "components/cards-horizontal-info-card" */).then(c => wrapFunctional(c.default || c))
export const CardsHorizontalInfoCardsList = () => import('../../components/Cards/HorizontalInfoCards/HorizontalInfoCardsList.vue' /* webpackChunkName: "components/cards-horizontal-info-cards-list" */).then(c => wrapFunctional(c.default || c))
export const CardsInternshipCardsIntenshipCompanyCard = () => import('../../components/Cards/InternshipCards/IntenshipCompanyCard.vue' /* webpackChunkName: "components/cards-internship-cards-intenship-company-card" */).then(c => wrapFunctional(c.default || c))
export const CardsInternshipVacancyProfessionCard = () => import('../../components/Cards/InternshipCards/InternshipVacancyProfessionCard.vue' /* webpackChunkName: "components/cards-internship-vacancy-profession-card" */).then(c => wrapFunctional(c.default || c))
export const CardsHrefCard = () => import('../../components/Cards/HrefCards/HrefCard.vue' /* webpackChunkName: "components/cards-href-card" */).then(c => wrapFunctional(c.default || c))
export const CardsHrefCardsList = () => import('../../components/Cards/HrefCards/HrefCardsList.vue' /* webpackChunkName: "components/cards-href-cards-list" */).then(c => wrapFunctional(c.default || c))
export const CardsMapCardsRegionCard = () => import('../../components/Cards/MapCards/RegionCard.vue' /* webpackChunkName: "components/cards-map-cards-region-card" */).then(c => wrapFunctional(c.default || c))
export const CardsNewsCard = () => import('../../components/Cards/NewsCards/NewsCard.vue' /* webpackChunkName: "components/cards-news-card" */).then(c => wrapFunctional(c.default || c))
export const CardsNewsCardsList = () => import('../../components/Cards/NewsCards/NewsCardsList.vue' /* webpackChunkName: "components/cards-news-cards-list" */).then(c => wrapFunctional(c.default || c))
export const CardsNewsRecommendationCard  = () => import('../../components/Cards/NewsCards/NewsRecommendationCard .vue' /* webpackChunkName: "components/cards-news-recommendation-card " */).then(c => wrapFunctional(c.default || c))
export const CardsNewsRecommendationsSlider = () => import('../../components/Cards/NewsRecommendationCards/NewsRecommendationsSlider.vue' /* webpackChunkName: "components/cards-news-recommendations-slider" */).then(c => wrapFunctional(c.default || c))
export const CardsProfessionCard = () => import('../../components/Cards/ProfessionCards/ProfessionCard.vue' /* webpackChunkName: "components/cards-profession-card" */).then(c => wrapFunctional(c.default || c))
export const CardsProfessionCardColourful = () => import('../../components/Cards/ProfessionCards/ProfessionCardColourful.vue' /* webpackChunkName: "components/cards-profession-card-colourful" */).then(c => wrapFunctional(c.default || c))
export const CardsProfessionCardsList = () => import('../../components/Cards/ProfessionCards/ProfessionCardsList.vue' /* webpackChunkName: "components/cards-profession-cards-list" */).then(c => wrapFunctional(c.default || c))
export const CardsProfessionalEducationProgramsCard = () => import('../../components/Cards/ProfessionalEducationProgramsCard/ProfessionalEducationProgramsCard.vue' /* webpackChunkName: "components/cards-professional-education-programs-card" */).then(c => wrapFunctional(c.default || c))
export const CardsProfessionalSkillCard = () => import('../../components/Cards/ProfessionalSkillCards/ProfessionalSkillCard.vue' /* webpackChunkName: "components/cards-professional-skill-card" */).then(c => wrapFunctional(c.default || c))
export const CardsProfessionalSkillCardsList = () => import('../../components/Cards/ProfessionalSkillCards/ProfessionalSkillCardsList.vue' /* webpackChunkName: "components/cards-professional-skill-cards-list" */).then(c => wrapFunctional(c.default || c))
export const CardsProfessionalSkillCardsSwiper = () => import('../../components/Cards/ProfessionalSkillCards/ProfessionalSkillCardsSwiper.vue' /* webpackChunkName: "components/cards-professional-skill-cards-swiper" */).then(c => wrapFunctional(c.default || c))
export const CardsQualificationCard = () => import('../../components/Cards/QualificationCards/QualificationCard.vue' /* webpackChunkName: "components/cards-qualification-card" */).then(c => wrapFunctional(c.default || c))
export const CardsQualificationCardsList = () => import('../../components/Cards/QualificationCards/QualificationCardsList.vue' /* webpackChunkName: "components/cards-qualification-cards-list" */).then(c => wrapFunctional(c.default || c))
export const CardsResumesCardsResumeCard = () => import('../../components/Cards/ResumesCards/ResumeCard.vue' /* webpackChunkName: "components/cards-resumes-cards-resume-card" */).then(c => wrapFunctional(c.default || c))
export const CardsResumesSubscriptionsCard = () => import('../../components/Cards/ResumesSubscriptionsCard/ResumesSubscriptionsCard.vue' /* webpackChunkName: "components/cards-resumes-subscriptions-card" */).then(c => wrapFunctional(c.default || c))
export const CardsStudyingProgramCard = () => import('../../components/Cards/StudyingProgramCards/StudyingProgramCard.vue' /* webpackChunkName: "components/cards-studying-program-card" */).then(c => wrapFunctional(c.default || c))
export const CardsStudyingProgramCardsList = () => import('../../components/Cards/StudyingProgramCards/StudyingProgramCardsList.vue' /* webpackChunkName: "components/cards-studying-program-cards-list" */).then(c => wrapFunctional(c.default || c))
export const CardsUniverCard = () => import('../../components/Cards/UniverCards/UniverCard.vue' /* webpackChunkName: "components/cards-univer-card" */).then(c => wrapFunctional(c.default || c))
export const CardsUniverCardsList = () => import('../../components/Cards/UniverCards/UniverCardsList.vue' /* webpackChunkName: "components/cards-univer-cards-list" */).then(c => wrapFunctional(c.default || c))
export const CardsUniversityCard = () => import('../../components/Cards/UniversityCards/UniversityCard.vue' /* webpackChunkName: "components/cards-university-card" */).then(c => wrapFunctional(c.default || c))
export const CardsUniversityCardsList = () => import('../../components/Cards/UniversityCards/UniversityCardsList.vue' /* webpackChunkName: "components/cards-university-cards-list" */).then(c => wrapFunctional(c.default || c))
export const CardsUniversityMiniCard = () => import('../../components/Cards/UniversityCards/UniversityMiniCard.vue' /* webpackChunkName: "components/cards-university-mini-card" */).then(c => wrapFunctional(c.default || c))
export const CardsUniversityInfoCard = () => import('../../components/Cards/UniversityInfoCards/UniversityInfoCard.vue' /* webpackChunkName: "components/cards-university-info-card" */).then(c => wrapFunctional(c.default || c))
export const CardsUniversityProfessionCard = () => import('../../components/Cards/UniversityProfessionCards/UniversityProfessionCard.vue' /* webpackChunkName: "components/cards-university-profession-card" */).then(c => wrapFunctional(c.default || c))
export const CardsUniversityProfessionCardsList = () => import('../../components/Cards/UniversityProfessionCards/UniversityProfessionCardsList.vue' /* webpackChunkName: "components/cards-university-profession-cards-list" */).then(c => wrapFunctional(c.default || c))
export const CardsUniversityProgramsCard = () => import('../../components/Cards/UniversityProgramsCard/UniversityProgramsCard.vue' /* webpackChunkName: "components/cards-university-programs-card" */).then(c => wrapFunctional(c.default || c))
export const CardsVacanciesCardsVacancyCard = () => import('../../components/Cards/VacanciesCards/VacancyCard.vue' /* webpackChunkName: "components/cards-vacancies-cards-vacancy-card" */).then(c => wrapFunctional(c.default || c))
export const CardsWorkCard = () => import('../../components/Cards/WorkCards/WorkCard.vue' /* webpackChunkName: "components/cards-work-card" */).then(c => wrapFunctional(c.default || c))
export const CardsWorkCardsList = () => import('../../components/Cards/WorkCards/WorkCardsList.vue' /* webpackChunkName: "components/cards-work-cards-list" */).then(c => wrapFunctional(c.default || c))
export const PageAgroLeaderBanner = () => import('../../components/Page/AgroLeader/AgroLeaderBanner.vue' /* webpackChunkName: "components/page-agro-leader-banner" */).then(c => wrapFunctional(c.default || c))
export const PageAgroLeaderParticipantCard = () => import('../../components/Page/AgroLeader/AgroLeaderParticipantCard.vue' /* webpackChunkName: "components/page-agro-leader-participant-card" */).then(c => wrapFunctional(c.default || c))
export const PageAgroLeaderPartnerCard = () => import('../../components/Page/AgroLeader/AgroLeaderPartnerCard.vue' /* webpackChunkName: "components/page-agro-leader-partner-card" */).then(c => wrapFunctional(c.default || c))
export const PageAgroLeaderPossibilityBanner = () => import('../../components/Page/AgroLeader/AgroLeaderPossibilityBanner.vue' /* webpackChunkName: "components/page-agro-leader-possibility-banner" */).then(c => wrapFunctional(c.default || c))
export const PageAgroLeaderStepCard = () => import('../../components/Page/AgroLeader/AgroLeaderStepCard.vue' /* webpackChunkName: "components/page-agro-leader-step-card" */).then(c => wrapFunctional(c.default || c))
export const PageAmbassadorsActiveInfoBanner = () => import('../../components/Page/Ambassadors/AmbassadorsActiveInfoBanner.vue' /* webpackChunkName: "components/page-ambassadors-active-info-banner" */).then(c => wrapFunctional(c.default || c))
export const PageAmbassadorsClubBanner = () => import('../../components/Page/Ambassadors/AmbassadorsClubBanner.vue' /* webpackChunkName: "components/page-ambassadors-club-banner" */).then(c => wrapFunctional(c.default || c))
export const PageAmbassadorsCreateSwiperCard = () => import('../../components/Page/Ambassadors/AmbassadorsCreateSwiperCard.vue' /* webpackChunkName: "components/page-ambassadors-create-swiper-card" */).then(c => wrapFunctional(c.default || c))
export const PageAmbassadorsForm = () => import('../../components/Page/Ambassadors/AmbassadorsForm.vue' /* webpackChunkName: "components/page-ambassadors-form" */).then(c => wrapFunctional(c.default || c))
export const PageAmbassadorsHowToBecomeBanner = () => import('../../components/Page/Ambassadors/AmbassadorsHowToBecomeBanner.vue' /* webpackChunkName: "components/page-ambassadors-how-to-become-banner" */).then(c => wrapFunctional(c.default || c))
export const PageAmbassadorsInfoBanner = () => import('../../components/Page/Ambassadors/AmbassadorsInfoBanner.vue' /* webpackChunkName: "components/page-ambassadors-info-banner" */).then(c => wrapFunctional(c.default || c))
export const PageAmbassadorsJoinBanner = () => import('../../components/Page/Ambassadors/AmbassadorsJoinBanner.vue' /* webpackChunkName: "components/page-ambassadors-join-banner" */).then(c => wrapFunctional(c.default || c))
export const PageAmbassadorsNecessaryBanner = () => import('../../components/Page/Ambassadors/AmbassadorsNecessaryBanner.vue' /* webpackChunkName: "components/page-ambassadors-necessary-banner" */).then(c => wrapFunctional(c.default || c))
export const PageAmbassadorsRequirementsBanner = () => import('../../components/Page/Ambassadors/AmbassadorsRequirementsBanner.vue' /* webpackChunkName: "components/page-ambassadors-requirements-banner" */).then(c => wrapFunctional(c.default || c))
export const PageAmbassadorsSwiperCard = () => import('../../components/Page/Ambassadors/AmbassadorsSwiperCard.vue' /* webpackChunkName: "components/page-ambassadors-swiper-card" */).then(c => wrapFunctional(c.default || c))
export const PageAmbassadorsTimeLine = () => import('../../components/Page/Ambassadors/AmbassadorsTimeLine.vue' /* webpackChunkName: "components/page-ambassadors-time-line" */).then(c => wrapFunctional(c.default || c))
export const PageApplicationAdditionally = () => import('../../components/Page/Application/Additionally.vue' /* webpackChunkName: "components/page-application-additionally" */).then(c => wrapFunctional(c.default || c))
export const PageApplicationAwards = () => import('../../components/Page/Application/Awards.vue' /* webpackChunkName: "components/page-application-awards" */).then(c => wrapFunctional(c.default || c))
export const PageApplicationConferences = () => import('../../components/Page/Application/Conferences.vue' /* webpackChunkName: "components/page-application-conferences" */).then(c => wrapFunctional(c.default || c))
export const PageApplicationContacts = () => import('../../components/Page/Application/Contacts.vue' /* webpackChunkName: "components/page-application-contacts" */).then(c => wrapFunctional(c.default || c))
export const PageApplicationEducation = () => import('../../components/Page/Application/Education.vue' /* webpackChunkName: "components/page-application-education" */).then(c => wrapFunctional(c.default || c))
export const PageApplicationPerfomance = () => import('../../components/Page/Application/Perfomance.vue' /* webpackChunkName: "components/page-application-perfomance" */).then(c => wrapFunctional(c.default || c))
export const PageApplicationPersonalData = () => import('../../components/Page/Application/PersonalData.vue' /* webpackChunkName: "components/page-application-personal-data" */).then(c => wrapFunctional(c.default || c))
export const PageApplicationPublications = () => import('../../components/Page/Application/Publications.vue' /* webpackChunkName: "components/page-application-publications" */).then(c => wrapFunctional(c.default || c))
export const PageApplicationForBankProductBanner = () => import('../../components/Page/ApplicationForBankProduct/ApplicationForBankProductBanner.vue' /* webpackChunkName: "components/page-application-for-bank-product-banner" */).then(c => wrapFunctional(c.default || c))
export const PageApplicationForBankProductBlock = () => import('../../components/Page/ApplicationForBankProduct/ApplicationForBankProductBlock.vue' /* webpackChunkName: "components/page-application-for-bank-product-block" */).then(c => wrapFunctional(c.default || c))
export const PageApplicationForBankProductForm = () => import('../../components/Page/ApplicationForBankProduct/ApplicationForBankProductForm.vue' /* webpackChunkName: "components/page-application-for-bank-product-form" */).then(c => wrapFunctional(c.default || c))
export const PageApplicationForBankProductList = () => import('../../components/Page/ApplicationForBankProduct/ApplicationForBankProductList.vue' /* webpackChunkName: "components/page-application-for-bank-product-list" */).then(c => wrapFunctional(c.default || c))
export const PageApplicationForBankProductModal = () => import('../../components/Page/ApplicationForBankProduct/ApplicationForBankProductModal.vue' /* webpackChunkName: "components/page-application-for-bank-product-modal" */).then(c => wrapFunctional(c.default || c))
export const PageCalculatorEGEBanner = () => import('../../components/Page/CalculatorEGE/CalculatorEGEBanner.vue' /* webpackChunkName: "components/page-calculator-e-g-e-banner" */).then(c => wrapFunctional(c.default || c))
export const PageCalculatorEGEForm = () => import('../../components/Page/CalculatorEGE/CalculatorEGEForm.vue' /* webpackChunkName: "components/page-calculator-e-g-e-form" */).then(c => wrapFunctional(c.default || c))
export const PageCalculatorEGEUniversities = () => import('../../components/Page/CalculatorEGE/CalculatorEGEUniversities.vue' /* webpackChunkName: "components/page-calculator-e-g-e-universities" */).then(c => wrapFunctional(c.default || c))
export const PageCareerMarathonBanner = () => import('../../components/Page/CareerMarathon/CareerMarathonBanner.vue' /* webpackChunkName: "components/page-career-marathon-banner" */).then(c => wrapFunctional(c.default || c))
export const PageCareerMarathonBannerAction = () => import('../../components/Page/CareerMarathon/CareerMarathonBannerAction.vue' /* webpackChunkName: "components/page-career-marathon-banner-action" */).then(c => wrapFunctional(c.default || c))
export const PageCareerMarathonBannerNoAuth = () => import('../../components/Page/CareerMarathon/CareerMarathonBannerNoAuth.vue' /* webpackChunkName: "components/page-career-marathon-banner-no-auth" */).then(c => wrapFunctional(c.default || c))
export const PageCareerMarathonLearnBanner = () => import('../../components/Page/CareerMarathon/CareerMarathonLearnBanner.vue' /* webpackChunkName: "components/page-career-marathon-learn-banner" */).then(c => wrapFunctional(c.default || c))
export const PageCareerMarathonScheduleBanner = () => import('../../components/Page/CareerMarathon/CareerMarathonScheduleBanner.vue' /* webpackChunkName: "components/page-career-marathon-schedule-banner" */).then(c => wrapFunctional(c.default || c))
export const PageCareerMarathonSpeakerCard = () => import('../../components/Page/CareerMarathon/CareerMarathonSpeakerCard.vue' /* webpackChunkName: "components/page-career-marathon-speaker-card" */).then(c => wrapFunctional(c.default || c))
export const PageCareerMarathonSpeakerInformationCardNumbers = () => import('../../components/Page/CareerMarathon/CareerMarathonSpeakerInformationCardNumbers.vue' /* webpackChunkName: "components/page-career-marathon-speaker-information-card-numbers" */).then(c => wrapFunctional(c.default || c))
export const PageCareerMarathonSpeakerInformationCardText = () => import('../../components/Page/CareerMarathon/CareerMarathonSpeakerInformationCardText.vue' /* webpackChunkName: "components/page-career-marathon-speaker-information-card-text" */).then(c => wrapFunctional(c.default || c))
export const PageCareerMarathonSpeakerInformationList = () => import('../../components/Page/CareerMarathon/CareerMarathonSpeakerInformationList.vue' /* webpackChunkName: "components/page-career-marathon-speaker-information-list" */).then(c => wrapFunctional(c.default || c))
export const PageCareerMarathonSuitableBanner = () => import('../../components/Page/CareerMarathon/CareerMarathonSuitableBanner.vue' /* webpackChunkName: "components/page-career-marathon-suitable-banner" */).then(c => wrapFunctional(c.default || c))
export const PageCareerMarathonTimeToEnd = () => import('../../components/Page/CareerMarathon/CareerMarathonTimeToEnd.vue' /* webpackChunkName: "components/page-career-marathon-time-to-end" */).then(c => wrapFunctional(c.default || c))
export const PageCareerMarathonVideoBlock = () => import('../../components/Page/CareerMarathon/CareerMarathonVideoBlock.vue' /* webpackChunkName: "components/page-career-marathon-video-block" */).then(c => wrapFunctional(c.default || c))
export const PageCareerMarathonWaitBanner = () => import('../../components/Page/CareerMarathon/CareerMarathonWaitBanner.vue' /* webpackChunkName: "components/page-career-marathon-wait-banner" */).then(c => wrapFunctional(c.default || c))
export const PageCareerMarathonTelegramBanner = () => import('../../components/Page/CareerMarathon/TelegramBanner.vue' /* webpackChunkName: "components/page-career-marathon-telegram-banner" */).then(c => wrapFunctional(c.default || c))
export const PageCareerRoutesBanner = () => import('../../components/Page/CareerRoutes/CareerRoutesBanner.vue' /* webpackChunkName: "components/page-career-routes-banner" */).then(c => wrapFunctional(c.default || c))
export const PageCareerRoutesDetailAboutVacancy = () => import('../../components/Page/CareerRoutes/CareerRoutesDetailAboutVacancy.vue' /* webpackChunkName: "components/page-career-routes-detail-about-vacancy" */).then(c => wrapFunctional(c.default || c))
export const PageCareerRoutesDetailBanner = () => import('../../components/Page/CareerRoutes/CareerRoutesDetailBanner.vue' /* webpackChunkName: "components/page-career-routes-detail-banner" */).then(c => wrapFunctional(c.default || c))
export const PageCareerRoutesDetailCompaniesList = () => import('../../components/Page/CareerRoutes/CareerRoutesDetailCompaniesList.vue' /* webpackChunkName: "components/page-career-routes-detail-companies-list" */).then(c => wrapFunctional(c.default || c))
export const PageCareerRoutesDetailCompetitions = () => import('../../components/Page/CareerRoutes/CareerRoutesDetailCompetitions.vue' /* webpackChunkName: "components/page-career-routes-detail-competitions" */).then(c => wrapFunctional(c.default || c))
export const PageCareerRoutesDetailDescription = () => import('../../components/Page/CareerRoutes/CareerRoutesDetailDescription.vue' /* webpackChunkName: "components/page-career-routes-detail-description" */).then(c => wrapFunctional(c.default || c))
export const PageCareerRoutesDetailHumanStories = () => import('../../components/Page/CareerRoutes/CareerRoutesDetailHumanStories.vue' /* webpackChunkName: "components/page-career-routes-detail-human-stories" */).then(c => wrapFunctional(c.default || c))
export const PageCareerRoutesDetailSimilarProfessions = () => import('../../components/Page/CareerRoutes/CareerRoutesDetailSimilarProfessions.vue' /* webpackChunkName: "components/page-career-routes-detail-similar-professions" */).then(c => wrapFunctional(c.default || c))
export const PageCareerRoutesDetailSkills = () => import('../../components/Page/CareerRoutes/CareerRoutesDetailSkills.vue' /* webpackChunkName: "components/page-career-routes-detail-skills" */).then(c => wrapFunctional(c.default || c))
export const PageCareerRoutesDetailVideo = () => import('../../components/Page/CareerRoutes/CareerRoutesDetailVideo.vue' /* webpackChunkName: "components/page-career-routes-detail-video" */).then(c => wrapFunctional(c.default || c))
export const PageCareerRoutesEducationalInstitutions = () => import('../../components/Page/CareerRoutes/CareerRoutesEducationalInstitutions.vue' /* webpackChunkName: "components/page-career-routes-educational-institutions" */).then(c => wrapFunctional(c.default || c))
export const PageCareerRoutesFilters = () => import('../../components/Page/CareerRoutes/CareerRoutesFilters.vue' /* webpackChunkName: "components/page-career-routes-filters" */).then(c => wrapFunctional(c.default || c))
export const PageCareerRoutesNews = () => import('../../components/Page/CareerRoutes/CareerRoutesNews.vue' /* webpackChunkName: "components/page-career-routes-news" */).then(c => wrapFunctional(c.default || c))
export const PageCoursesCourseCard = () => import('../../components/Page/Courses/CourseCard.vue' /* webpackChunkName: "components/page-courses-course-card" */).then(c => wrapFunctional(c.default || c))
export const PageDigitalProfileDigitialProfileCreateBanner = () => import('../../components/Page/DigitalProfile/DigitialProfileCreateBanner.vue' /* webpackChunkName: "components/page-digital-profile-digitial-profile-create-banner" */).then(c => wrapFunctional(c.default || c))
export const PageDigitalProfileDigitialProfileInfoBanner = () => import('../../components/Page/DigitalProfile/DigitialProfileInfoBanner.vue' /* webpackChunkName: "components/page-digital-profile-digitial-profile-info-banner" */).then(c => wrapFunctional(c.default || c))
export const PageDigitalProfileDigitialProfileNdaModal = () => import('../../components/Page/DigitalProfile/DigitialProfileNdaModal.vue' /* webpackChunkName: "components/page-digital-profile-digitial-profile-nda-modal" */).then(c => wrapFunctional(c.default || c))
export const PageDigitalProfileDigitialProfileProgressBanner = () => import('../../components/Page/DigitalProfile/DigitialProfileProgressBanner.vue' /* webpackChunkName: "components/page-digital-profile-digitial-profile-progress-banner" */).then(c => wrapFunctional(c.default || c))
export const PageDigitalProfileDigitialProfileProgressStep = () => import('../../components/Page/DigitalProfile/DigitialProfileProgressStep.vue' /* webpackChunkName: "components/page-digital-profile-digitial-profile-progress-step" */).then(c => wrapFunctional(c.default || c))
export const PageEmployersTasksEmployerTaskCard = () => import('../../components/Page/EmployersTasks/EmployerTaskCard.vue' /* webpackChunkName: "components/page-employers-tasks-employer-task-card" */).then(c => wrapFunctional(c.default || c))
export const PageEmployersTasksEmployerTaskProfile = () => import('../../components/Page/EmployersTasks/EmployerTaskProfile.vue' /* webpackChunkName: "components/page-employers-tasks-employer-task-profile" */).then(c => wrapFunctional(c.default || c))
export const PageEmployersTaskMissionBanner = () => import('../../components/Page/EmployersTasks/EmployersTaskMissionBanner.vue' /* webpackChunkName: "components/page-employers-task-mission-banner" */).then(c => wrapFunctional(c.default || c))
export const PageEmployersTaskPartnerSlide = () => import('../../components/Page/EmployersTasks/EmployersTaskPartnerSlide.vue' /* webpackChunkName: "components/page-employers-task-partner-slide" */).then(c => wrapFunctional(c.default || c))
export const PageEmployersTaskPartnerSlider = () => import('../../components/Page/EmployersTasks/EmployersTaskPartnerSlider.vue' /* webpackChunkName: "components/page-employers-task-partner-slider" */).then(c => wrapFunctional(c.default || c))
export const PageEmployersTaskVacancySlide = () => import('../../components/Page/EmployersTasks/EmployersTaskVacancySlide.vue' /* webpackChunkName: "components/page-employers-task-vacancy-slide" */).then(c => wrapFunctional(c.default || c))
export const PageEmployersTaskWinnerSlide = () => import('../../components/Page/EmployersTasks/EmployersTaskWinnerSlide.vue' /* webpackChunkName: "components/page-employers-task-winner-slide" */).then(c => wrapFunctional(c.default || c))
export const PageEmployersTasksBanner = () => import('../../components/Page/EmployersTasks/EmployersTasksBanner.vue' /* webpackChunkName: "components/page-employers-tasks-banner" */).then(c => wrapFunctional(c.default || c))
export const PageEmployersTasksForm = () => import('../../components/Page/EmployersTasks/EmployersTasksForm.vue' /* webpackChunkName: "components/page-employers-tasks-form" */).then(c => wrapFunctional(c.default || c))
export const PageEmployersTasksImageSlide = () => import('../../components/Page/EmployersTasks/EmployersTasksImageSlide.vue' /* webpackChunkName: "components/page-employers-tasks-image-slide" */).then(c => wrapFunctional(c.default || c))
export const PageEmployersTasksList = () => import('../../components/Page/EmployersTasks/EmployersTasksList.vue' /* webpackChunkName: "components/page-employers-tasks-list" */).then(c => wrapFunctional(c.default || c))
export const PageEmployersTasksListItem = () => import('../../components/Page/EmployersTasks/EmployersTasksListItem.vue' /* webpackChunkName: "components/page-employers-tasks-list-item" */).then(c => wrapFunctional(c.default || c))
export const PageEmployersTasksRedirectBanner = () => import('../../components/Page/EmployersTasks/EmployersTasksRedirectBanner.vue' /* webpackChunkName: "components/page-employers-tasks-redirect-banner" */).then(c => wrapFunctional(c.default || c))
export const PageEmployersTasksSlider = () => import('../../components/Page/EmployersTasks/EmployersTasksSlider.vue' /* webpackChunkName: "components/page-employers-tasks-slider" */).then(c => wrapFunctional(c.default || c))
export const PageEventAbout = () => import('../../components/Page/Event/EventAbout.vue' /* webpackChunkName: "components/page-event-about" */).then(c => wrapFunctional(c.default || c))
export const PageEventBanner = () => import('../../components/Page/Event/EventBanner.vue' /* webpackChunkName: "components/page-event-banner" */).then(c => wrapFunctional(c.default || c))
export const PageEventTimeLine = () => import('../../components/Page/Event/EventTimeLine.vue' /* webpackChunkName: "components/page-event-time-line" */).then(c => wrapFunctional(c.default || c))
export const PageEventTimeLineList = () => import('../../components/Page/Event/EventTimeLineList.vue' /* webpackChunkName: "components/page-event-time-line-list" */).then(c => wrapFunctional(c.default || c))
export const PageExecutiveApproachCard = () => import('../../components/Page/ExecutiveSearch/ExecutiveApproachCard.vue' /* webpackChunkName: "components/page-executive-approach-card" */).then(c => wrapFunctional(c.default || c))
export const PageExecutiveBanner = () => import('../../components/Page/ExecutiveSearch/ExecutiveBanner.vue' /* webpackChunkName: "components/page-executive-banner" */).then(c => wrapFunctional(c.default || c))
export const PageExecutiveBlock = () => import('../../components/Page/ExecutiveSearch/ExecutiveBlock.vue' /* webpackChunkName: "components/page-executive-block" */).then(c => wrapFunctional(c.default || c))
export const PageExecutiveCandidateCard = () => import('../../components/Page/ExecutiveSearch/ExecutiveCandidateCard.vue' /* webpackChunkName: "components/page-executive-candidate-card" */).then(c => wrapFunctional(c.default || c))
export const PageExecutiveForm = () => import('../../components/Page/ExecutiveSearch/ExecutiveForm.vue' /* webpackChunkName: "components/page-executive-form" */).then(c => wrapFunctional(c.default || c))
export const PageExecutiveHelpedCard = () => import('../../components/Page/ExecutiveSearch/ExecutiveHelpedCard.vue' /* webpackChunkName: "components/page-executive-helped-card" */).then(c => wrapFunctional(c.default || c))
export const PageExecutiveInfoCard = () => import('../../components/Page/ExecutiveSearch/ExecutiveInfoCard.vue' /* webpackChunkName: "components/page-executive-info-card" */).then(c => wrapFunctional(c.default || c))
export const PageExecutiveIssuesCard = () => import('../../components/Page/ExecutiveSearch/ExecutiveIssuesCard.vue' /* webpackChunkName: "components/page-executive-issues-card" */).then(c => wrapFunctional(c.default || c))
export const PageExecutivePossibilitiesCard = () => import('../../components/Page/ExecutiveSearch/ExecutivePossibilitiesCard.vue' /* webpackChunkName: "components/page-executive-possibilities-card" */).then(c => wrapFunctional(c.default || c))
export const PageExecutiveSearchModal = () => import('../../components/Page/ExecutiveSearch/ExecutiveSearchModal.vue' /* webpackChunkName: "components/page-executive-search-modal" */).then(c => wrapFunctional(c.default || c))
export const PageKnowledgeDayBanner = () => import('../../components/Page/KnowledgeDay/KnowledgeDayBanner.vue' /* webpackChunkName: "components/page-knowledge-day-banner" */).then(c => wrapFunctional(c.default || c))
export const PageKnowledgeDayGrant = () => import('../../components/Page/KnowledgeDay/KnowledgeDayGrant.vue' /* webpackChunkName: "components/page-knowledge-day-grant" */).then(c => wrapFunctional(c.default || c))
export const PageKnowledgeDayProjects = () => import('../../components/Page/KnowledgeDay/KnowledgeDayProjects.vue' /* webpackChunkName: "components/page-knowledge-day-projects" */).then(c => wrapFunctional(c.default || c))
export const PageKnowledgeDayRegister = () => import('../../components/Page/KnowledgeDay/KnowledgeDayRegister.vue' /* webpackChunkName: "components/page-knowledge-day-register" */).then(c => wrapFunctional(c.default || c))
export const PageKnowledgeDayShare = () => import('../../components/Page/KnowledgeDay/KnowledgeDayShare.vue' /* webpackChunkName: "components/page-knowledge-day-share" */).then(c => wrapFunctional(c.default || c))
export const PageLandingsLandingBanner = () => import('../../components/Page/Landings/LandingBanner.vue' /* webpackChunkName: "components/page-landings-landing-banner" */).then(c => wrapFunctional(c.default || c))
export const PageLandingsLandingBenefits = () => import('../../components/Page/Landings/LandingBenefits.vue' /* webpackChunkName: "components/page-landings-landing-benefits" */).then(c => wrapFunctional(c.default || c))
export const PageLandingsLandingFeedbackForm = () => import('../../components/Page/Landings/LandingFeedbackForm.vue' /* webpackChunkName: "components/page-landings-landing-feedback-form" */).then(c => wrapFunctional(c.default || c))
export const PageLandingsLandingImage = () => import('../../components/Page/Landings/LandingImage.vue' /* webpackChunkName: "components/page-landings-landing-image" */).then(c => wrapFunctional(c.default || c))
export const PageLandingsLandingRectangularBanner = () => import('../../components/Page/Landings/LandingRectangularBanner.vue' /* webpackChunkName: "components/page-landings-landing-rectangular-banner" */).then(c => wrapFunctional(c.default || c))
export const PageLandingsLandingSectionTextImage = () => import('../../components/Page/Landings/LandingSectionTextImage.vue' /* webpackChunkName: "components/page-landings-landing-section-text-image" */).then(c => wrapFunctional(c.default || c))
export const PageLandingsLandingSlider = () => import('../../components/Page/Landings/LandingSlider.vue' /* webpackChunkName: "components/page-landings-landing-slider" */).then(c => wrapFunctional(c.default || c))
export const PageLandingsLandingTextBlock = () => import('../../components/Page/Landings/LandingTextBlock.vue' /* webpackChunkName: "components/page-landings-landing-text-block" */).then(c => wrapFunctional(c.default || c))
export const PageLandingsLandingVideo = () => import('../../components/Page/Landings/LandingVideo.vue' /* webpackChunkName: "components/page-landings-landing-video" */).then(c => wrapFunctional(c.default || c))
export const PageLookingForInternshipLFInternshipBenefitBanner = () => import('../../components/Page/LookingForInternship/LFInternshipBenefitBanner.vue' /* webpackChunkName: "components/page-looking-for-internship-l-f-internship-benefit-banner" */).then(c => wrapFunctional(c.default || c))
export const PageLookingForInternshipLFInternshipCarousel = () => import('../../components/Page/LookingForInternship/LFInternshipCarousel.vue' /* webpackChunkName: "components/page-looking-for-internship-l-f-internship-carousel" */).then(c => wrapFunctional(c.default || c))
export const PageLookingForInternshipLFInternshipGraduatesCard = () => import('../../components/Page/LookingForInternship/LFInternshipGraduatesCard.vue' /* webpackChunkName: "components/page-looking-for-internship-l-f-internship-graduates-card" */).then(c => wrapFunctional(c.default || c))
export const PageLookingForInternshipLFInternshipInformationBanner = () => import('../../components/Page/LookingForInternship/LFInternshipInformationBanner.vue' /* webpackChunkName: "components/page-looking-for-internship-l-f-internship-information-banner" */).then(c => wrapFunctional(c.default || c))
export const PageLookingForInternshipLFInternshipSchoolCarousel = () => import('../../components/Page/LookingForInternship/LFInternshipSchoolCarousel.vue' /* webpackChunkName: "components/page-looking-for-internship-l-f-internship-school-carousel" */).then(c => wrapFunctional(c.default || c))
export const PageLookingForInternshipLFInternshipSubjectCard = () => import('../../components/Page/LookingForInternship/LFInternshipSubjectCard.vue' /* webpackChunkName: "components/page-looking-for-internship-l-f-internship-subject-card" */).then(c => wrapFunctional(c.default || c))
export const PageLookingForInternshipBanner = () => import('../../components/Page/LookingForInternship/LookingForInternshipBanner.vue' /* webpackChunkName: "components/page-looking-for-internship-banner" */).then(c => wrapFunctional(c.default || c))
export const PageLookingForJobCarouselCompaninesProfession = () => import('../../components/Page/LookingForJob/CarouselCompaninesProfession.vue' /* webpackChunkName: "components/page-looking-for-job-carousel-companines-profession" */).then(c => wrapFunctional(c.default || c))
export const PageLookingForJobCarouselHelped = () => import('../../components/Page/LookingForJob/CarouselHelped.vue' /* webpackChunkName: "components/page-looking-for-job-carousel-helped" */).then(c => wrapFunctional(c.default || c))
export const PageLookingForJobCarouselProfession = () => import('../../components/Page/LookingForJob/CarouselProfession.vue' /* webpackChunkName: "components/page-looking-for-job-carousel-profession" */).then(c => wrapFunctional(c.default || c))
export const PageLookingForJobCarouselVacancies = () => import('../../components/Page/LookingForJob/CarouselVacancies.vue' /* webpackChunkName: "components/page-looking-for-job-carousel-vacancies" */).then(c => wrapFunctional(c.default || c))
export const PageLookingForJobInternshipBanner = () => import('../../components/Page/LookingForJob/InternshipBanner.vue' /* webpackChunkName: "components/page-looking-for-job-internship-banner" */).then(c => wrapFunctional(c.default || c))
export const PageLookingForJobPersonalAPKBanner = () => import('../../components/Page/LookingForJob/PersonalAPKBanner.vue' /* webpackChunkName: "components/page-looking-for-job-personal-a-p-k-banner" */).then(c => wrapFunctional(c.default || c))
export const PageLookingForJobProfessionCard = () => import('../../components/Page/LookingForJob/ProfessionCard.vue' /* webpackChunkName: "components/page-looking-for-job-profession-card" */).then(c => wrapFunctional(c.default || c))
export const PageLookingForJobProfessionList = () => import('../../components/Page/LookingForJob/ProfessionList.vue' /* webpackChunkName: "components/page-looking-for-job-profession-list" */).then(c => wrapFunctional(c.default || c))
export const PageLookingForJobUsefulBannerCard = () => import('../../components/Page/LookingForJob/UsefulBannerCard.vue' /* webpackChunkName: "components/page-looking-for-job-useful-banner-card" */).then(c => wrapFunctional(c.default || c))
export const PageLookingForJobUsefulBannerList = () => import('../../components/Page/LookingForJob/UsefulBannerList.vue' /* webpackChunkName: "components/page-looking-for-job-useful-banner-list" */).then(c => wrapFunctional(c.default || c))
export const PageMainAgroBanner = () => import('../../components/Page/Main/MainAgroBanner.vue' /* webpackChunkName: "components/page-main-agro-banner" */).then(c => wrapFunctional(c.default || c))
export const PageMainPartnerCard = () => import('../../components/Page/Main/PartnerCard.vue' /* webpackChunkName: "components/page-main-partner-card" */).then(c => wrapFunctional(c.default || c))
export const PageMainPartnerSwiperList = () => import('../../components/Page/Main/PartnerSwiperList.vue' /* webpackChunkName: "components/page-main-partner-swiper-list" */).then(c => wrapFunctional(c.default || c))
export const PageOlympiadApplication = () => import('../../components/Page/Olympiad/OlympiadApplication.vue' /* webpackChunkName: "components/page-olympiad-application" */).then(c => wrapFunctional(c.default || c))
export const PageOlympiadBanner = () => import('../../components/Page/Olympiad/OlympiadBanner.vue' /* webpackChunkName: "components/page-olympiad-banner" */).then(c => wrapFunctional(c.default || c))
export const PageOlympiadBroadcast = () => import('../../components/Page/Olympiad/OlympiadBroadcast.vue' /* webpackChunkName: "components/page-olympiad-broadcast" */).then(c => wrapFunctional(c.default || c))
export const PageOlympiadBroadcastBanner = () => import('../../components/Page/Olympiad/OlympiadBroadcastBanner.vue' /* webpackChunkName: "components/page-olympiad-broadcast-banner" */).then(c => wrapFunctional(c.default || c))
export const PageOlympiadContentBanner = () => import('../../components/Page/Olympiad/OlympiadContentBanner.vue' /* webpackChunkName: "components/page-olympiad-content-banner" */).then(c => wrapFunctional(c.default || c))
export const PageOlympiadDirectionCard = () => import('../../components/Page/Olympiad/OlympiadDirectionCard.vue' /* webpackChunkName: "components/page-olympiad-direction-card" */).then(c => wrapFunctional(c.default || c))
export const PageOlympiadDirections = () => import('../../components/Page/Olympiad/OlympiadDirections.vue' /* webpackChunkName: "components/page-olympiad-directions" */).then(c => wrapFunctional(c.default || c))
export const PageOlympiadEvents = () => import('../../components/Page/Olympiad/OlympiadEvents.vue' /* webpackChunkName: "components/page-olympiad-events" */).then(c => wrapFunctional(c.default || c))
export const PageOlympiadGifts = () => import('../../components/Page/Olympiad/OlympiadGifts.vue' /* webpackChunkName: "components/page-olympiad-gifts" */).then(c => wrapFunctional(c.default || c))
export const PageOlympiadGrantCard = () => import('../../components/Page/Olympiad/OlympiadGrantCard.vue' /* webpackChunkName: "components/page-olympiad-grant-card" */).then(c => wrapFunctional(c.default || c))
export const PageOlympiadGrants = () => import('../../components/Page/Olympiad/OlympiadGrants.vue' /* webpackChunkName: "components/page-olympiad-grants" */).then(c => wrapFunctional(c.default || c))
export const PageOlympiadParticipants = () => import('../../components/Page/Olympiad/OlympiadParticipants.vue' /* webpackChunkName: "components/page-olympiad-participants" */).then(c => wrapFunctional(c.default || c))
export const PageOlympiadParticipantsCard = () => import('../../components/Page/Olympiad/OlympiadParticipantsCard.vue' /* webpackChunkName: "components/page-olympiad-participants-card" */).then(c => wrapFunctional(c.default || c))
export const PageOlympiadReasons = () => import('../../components/Page/Olympiad/OlympiadReasons.vue' /* webpackChunkName: "components/page-olympiad-reasons" */).then(c => wrapFunctional(c.default || c))
export const PageOlympiadReasonsCard = () => import('../../components/Page/Olympiad/OlympiadReasonsCard.vue' /* webpackChunkName: "components/page-olympiad-reasons-card" */).then(c => wrapFunctional(c.default || c))
export const PageOlympiadSchedule = () => import('../../components/Page/Olympiad/OlympiadSchedule.vue' /* webpackChunkName: "components/page-olympiad-schedule" */).then(c => wrapFunctional(c.default || c))
export const PageOlympiadScheduleCard = () => import('../../components/Page/Olympiad/OlympiadScheduleCard.vue' /* webpackChunkName: "components/page-olympiad-schedule-card" */).then(c => wrapFunctional(c.default || c))
export const PageOlympiadSteps = () => import('../../components/Page/Olympiad/OlympiadSteps.vue' /* webpackChunkName: "components/page-olympiad-steps" */).then(c => wrapFunctional(c.default || c))
export const PageOlympiadStepsCard = () => import('../../components/Page/Olympiad/OlympiadStepsCard.vue' /* webpackChunkName: "components/page-olympiad-steps-card" */).then(c => wrapFunctional(c.default || c))
export const PageProfitableDepositBanner = () => import('../../components/Page/ProfitableDeposit/ProfitableDepositBanner.vue' /* webpackChunkName: "components/page-profitable-deposit-banner" */).then(c => wrapFunctional(c.default || c))
export const PageProfitableDepositConditions = () => import('../../components/Page/ProfitableDeposit/ProfitableDepositConditions.vue' /* webpackChunkName: "components/page-profitable-deposit-conditions" */).then(c => wrapFunctional(c.default || c))
export const PageProfitableDepositConstructor = () => import('../../components/Page/ProfitableDeposit/ProfitableDepositConstructor.vue' /* webpackChunkName: "components/page-profitable-deposit-constructor" */).then(c => wrapFunctional(c.default || c))
export const PageProfitableDepositInfo = () => import('../../components/Page/ProfitableDeposit/ProfitableDepositInfo.vue' /* webpackChunkName: "components/page-profitable-deposit-info" */).then(c => wrapFunctional(c.default || c))
export const PageResumesFilters = () => import('../../components/Page/Resumes/ResumesFilters.vue' /* webpackChunkName: "components/page-resumes-filters" */).then(c => wrapFunctional(c.default || c))
export const PageResumesFiltersNotification = () => import('../../components/Page/Resumes/ResumesFiltersNotification.vue' /* webpackChunkName: "components/page-resumes-filters-notification" */).then(c => wrapFunctional(c.default || c))
export const PageResumesNotFound = () => import('../../components/Page/Resumes/ResumesNotFound.vue' /* webpackChunkName: "components/page-resumes-not-found" */).then(c => wrapFunctional(c.default || c))
export const PageScholarshipCondition = () => import('../../components/Page/ScholarshipProgram/ScholarshipCondition.vue' /* webpackChunkName: "components/page-scholarship-condition" */).then(c => wrapFunctional(c.default || c))
export const PageScholarshipConditionList = () => import('../../components/Page/ScholarshipProgram/ScholarshipConditionList.vue' /* webpackChunkName: "components/page-scholarship-condition-list" */).then(c => wrapFunctional(c.default || c))
export const PageScholarshipProfession = () => import('../../components/Page/ScholarshipProgram/ScholarshipProfession.vue' /* webpackChunkName: "components/page-scholarship-profession" */).then(c => wrapFunctional(c.default || c))
export const PageScholarshipProfessionList = () => import('../../components/Page/ScholarshipProgram/ScholarshipProfessionList.vue' /* webpackChunkName: "components/page-scholarship-profession-list" */).then(c => wrapFunctional(c.default || c))
export const PageScholarshipProgramBanner = () => import('../../components/Page/ScholarshipProgram/ScholarshipProgramBanner.vue' /* webpackChunkName: "components/page-scholarship-program-banner" */).then(c => wrapFunctional(c.default || c))
export const PageScholarshipProgramCalendarBanner = () => import('../../components/Page/ScholarshipProgram/ScholarshipProgramCalendarBanner.vue' /* webpackChunkName: "components/page-scholarship-program-calendar-banner" */).then(c => wrapFunctional(c.default || c))
export const PageScholarshipProgramInformationBanner = () => import('../../components/Page/ScholarshipProgram/ScholarshipProgramInformationBanner.vue' /* webpackChunkName: "components/page-scholarship-program-information-banner" */).then(c => wrapFunctional(c.default || c))
export const PageScholarshipProgramInvitionBanner = () => import('../../components/Page/ScholarshipProgram/ScholarshipProgramInvitionBanner.vue' /* webpackChunkName: "components/page-scholarship-program-invition-banner" */).then(c => wrapFunctional(c.default || c))
export const PageScholarshipProgramNecessaryBanner = () => import('../../components/Page/ScholarshipProgram/ScholarshipProgramNecessaryBanner.vue' /* webpackChunkName: "components/page-scholarship-program-necessary-banner" */).then(c => wrapFunctional(c.default || c))
export const PageScholarshipProgramResultBanner = () => import('../../components/Page/ScholarshipProgram/ScholarshipProgramResultBanner.vue' /* webpackChunkName: "components/page-scholarship-program-result-banner" */).then(c => wrapFunctional(c.default || c))
export const PageScholarshipProgramSelectionBanner = () => import('../../components/Page/ScholarshipProgram/ScholarshipProgramSelectionBanner.vue' /* webpackChunkName: "components/page-scholarship-program-selection-banner" */).then(c => wrapFunctional(c.default || c))
export const PageScholarshipProgramTimeLine = () => import('../../components/Page/ScholarshipProgram/ScholarshipProgramTimeLine.vue' /* webpackChunkName: "components/page-scholarship-program-time-line" */).then(c => wrapFunctional(c.default || c))
export const PageScholarshipProgramUniversities = () => import('../../components/Page/ScholarshipProgram/ScholarshipProgramUniversities.vue' /* webpackChunkName: "components/page-scholarship-program-universities" */).then(c => wrapFunctional(c.default || c))
export const PageScholarshipProgramUniversity = () => import('../../components/Page/ScholarshipProgram/ScholarshipProgramUniversity.vue' /* webpackChunkName: "components/page-scholarship-program-university" */).then(c => wrapFunctional(c.default || c))
export const PageScholarshipTelegramBanner = () => import('../../components/Page/ScholarshipProgram/ScholarshipTelegramBanner.vue' /* webpackChunkName: "components/page-scholarship-telegram-banner" */).then(c => wrapFunctional(c.default || c))
export const PageStudentActivitiesCard = () => import('../../components/Page/StudentActivities/StudentActivitiesCard.vue' /* webpackChunkName: "components/page-student-activities-card" */).then(c => wrapFunctional(c.default || c))
export const PageStudentActivitiesFilters = () => import('../../components/Page/StudentActivities/StudentActivitiesFilters.vue' /* webpackChunkName: "components/page-student-activities-filters" */).then(c => wrapFunctional(c.default || c))
export const PageStudentActivitiesForm = () => import('../../components/Page/StudentActivities/StudentActivitiesForm.vue' /* webpackChunkName: "components/page-student-activities-form" */).then(c => wrapFunctional(c.default || c))
export const PageStudentActivitiesModal = () => import('../../components/Page/StudentActivities/StudentActivitiesModal.vue' /* webpackChunkName: "components/page-student-activities-modal" */).then(c => wrapFunctional(c.default || c))
export const PageStudentActivitiesPageBanner = () => import('../../components/Page/StudentActivities/StudentActivitiesPageBanner.vue' /* webpackChunkName: "components/page-student-activities-page-banner" */).then(c => wrapFunctional(c.default || c))
export const PageStudentsStudentAfishaBanner = () => import('../../components/Page/Students/StudentAfishaBanner.vue' /* webpackChunkName: "components/page-students-student-afisha-banner" */).then(c => wrapFunctional(c.default || c))
export const PageStudentsStudentAkseleratorBanner = () => import('../../components/Page/Students/StudentAkseleratorBanner.vue' /* webpackChunkName: "components/page-students-student-akselerator-banner" */).then(c => wrapFunctional(c.default || c))
export const PageStudentsStudentAmbassadorsBanner = () => import('../../components/Page/Students/StudentAmbassadorsBanner.vue' /* webpackChunkName: "components/page-students-student-ambassadors-banner" */).then(c => wrapFunctional(c.default || c))
export const PageStudentsStudentCompaniesCard = () => import('../../components/Page/Students/StudentCompaniesCard.vue' /* webpackChunkName: "components/page-students-student-companies-card" */).then(c => wrapFunctional(c.default || c))
export const PageStudentsStudentCompaniesSwiper = () => import('../../components/Page/Students/StudentCompaniesSwiper.vue' /* webpackChunkName: "components/page-students-student-companies-swiper" */).then(c => wrapFunctional(c.default || c))
export const PageStudentsStudentCompetitionCard = () => import('../../components/Page/Students/StudentCompetitionCard.vue' /* webpackChunkName: "components/page-students-student-competition-card" */).then(c => wrapFunctional(c.default || c))
export const PageStudentsStudentDirectionCard = () => import('../../components/Page/Students/StudentDirectionCard.vue' /* webpackChunkName: "components/page-students-student-direction-card" */).then(c => wrapFunctional(c.default || c))
export const PageStudentsStudentDirectionCardList = () => import('../../components/Page/Students/StudentDirectionCardList.vue' /* webpackChunkName: "components/page-students-student-direction-card-list" */).then(c => wrapFunctional(c.default || c))
export const PageStudentsStudentEmployersTasks = () => import('../../components/Page/Students/StudentEmployersTasks.vue' /* webpackChunkName: "components/page-students-student-employers-tasks" */).then(c => wrapFunctional(c.default || c))
export const PageStudentsStudentEventsCard = () => import('../../components/Page/Students/StudentEventsCard.vue' /* webpackChunkName: "components/page-students-student-events-card" */).then(c => wrapFunctional(c.default || c))
export const PageStudentsStudentHackathon = () => import('../../components/Page/Students/StudentHackathon.vue' /* webpackChunkName: "components/page-students-student-hackathon" */).then(c => wrapFunctional(c.default || c))
export const PageStudentsStudentImproveSkills = () => import('../../components/Page/Students/StudentImproveSkills.vue' /* webpackChunkName: "components/page-students-student-improve-skills" */).then(c => wrapFunctional(c.default || c))
export const PageStudentsStudentInternshipCard = () => import('../../components/Page/Students/StudentInternshipCard.vue' /* webpackChunkName: "components/page-students-student-internship-card" */).then(c => wrapFunctional(c.default || c))
export const PageStudentsStudentInternshipCompanyBanner = () => import('../../components/Page/Students/StudentInternshipCompanyBanner.vue' /* webpackChunkName: "components/page-students-student-internship-company-banner" */).then(c => wrapFunctional(c.default || c))
export const PageStudentsStudentInternshipCompanyCard = () => import('../../components/Page/Students/StudentInternshipCompanyCard.vue' /* webpackChunkName: "components/page-students-student-internship-company-card" */).then(c => wrapFunctional(c.default || c))
export const PageStudentsStudentInternshipSearch = () => import('../../components/Page/Students/StudentInternshipSearch.vue' /* webpackChunkName: "components/page-students-student-internship-search" */).then(c => wrapFunctional(c.default || c))
export const PageStudentsStudentInternshipsList = () => import('../../components/Page/Students/StudentInternshipsList.vue' /* webpackChunkName: "components/page-students-student-internships-list" */).then(c => wrapFunctional(c.default || c))
export const PageStudentsStudentPartOfCareer = () => import('../../components/Page/Students/StudentPartOfCareer.vue' /* webpackChunkName: "components/page-students-student-part-of-career" */).then(c => wrapFunctional(c.default || c))
export const PageStudentsStudentPathList = () => import('../../components/Page/Students/StudentPathList.vue' /* webpackChunkName: "components/page-students-student-path-list" */).then(c => wrapFunctional(c.default || c))
export const PageStudentsStudentProfessionCard = () => import('../../components/Page/Students/StudentProfessionCard.vue' /* webpackChunkName: "components/page-students-student-profession-card" */).then(c => wrapFunctional(c.default || c))
export const PageStudentsStudentProfessionList = () => import('../../components/Page/Students/StudentProfessionList.vue' /* webpackChunkName: "components/page-students-student-profession-list" */).then(c => wrapFunctional(c.default || c))
export const PageStudentsStudentScholarship = () => import('../../components/Page/Students/StudentScholarship.vue' /* webpackChunkName: "components/page-students-student-scholarship" */).then(c => wrapFunctional(c.default || c))
export const PageSubjectNewsCard = () => import('../../components/Page/Subject/NewsCard.vue' /* webpackChunkName: "components/page-subject-news-card" */).then(c => wrapFunctional(c.default || c))
export const PageSubjectNewsCardWrapper = () => import('../../components/Page/Subject/NewsCardWrapper.vue' /* webpackChunkName: "components/page-subject-news-card-wrapper" */).then(c => wrapFunctional(c.default || c))
export const PageSubjectNewsContainer = () => import('../../components/Page/Subject/NewsContainer.vue' /* webpackChunkName: "components/page-subject-news-container" */).then(c => wrapFunctional(c.default || c))
export const PageSubjectBanner = () => import('../../components/Page/Subject/SubjectBanner.vue' /* webpackChunkName: "components/page-subject-banner" */).then(c => wrapFunctional(c.default || c))
export const PageSupportItem = () => import('../../components/Page/Support/SupportItem.vue' /* webpackChunkName: "components/page-support-item" */).then(c => wrapFunctional(c.default || c))
export const PageSupportList = () => import('../../components/Page/Support/SupportList.vue' /* webpackChunkName: "components/page-support-list" */).then(c => wrapFunctional(c.default || c))
export const PageUniversityAbout = () => import('../../components/Page/University/UniversityAbout.vue' /* webpackChunkName: "components/page-university-about" */).then(c => wrapFunctional(c.default || c))
export const PageUniversityBanner = () => import('../../components/Page/University/UniversityBanner.vue' /* webpackChunkName: "components/page-university-banner" */).then(c => wrapFunctional(c.default || c))
export const PageUniversityDetailPage = () => import('../../components/Page/University/UniversityDetailPage.vue' /* webpackChunkName: "components/page-university-detail-page" */).then(c => wrapFunctional(c.default || c))
export const PageUniversityDetailSlider = () => import('../../components/Page/University/UniversityDetailSlider.vue' /* webpackChunkName: "components/page-university-detail-slider" */).then(c => wrapFunctional(c.default || c))
export const PageUniversityDirectionCard = () => import('../../components/Page/University/UniversityDirectionCard.vue' /* webpackChunkName: "components/page-university-direction-card" */).then(c => wrapFunctional(c.default || c))
export const PageUniversityDirectionList = () => import('../../components/Page/University/UniversityDirectionList.vue' /* webpackChunkName: "components/page-university-direction-list" */).then(c => wrapFunctional(c.default || c))
export const PageUniversityInfoBlocks = () => import('../../components/Page/University/UniversityInfoBlocks.vue' /* webpackChunkName: "components/page-university-info-blocks" */).then(c => wrapFunctional(c.default || c))
export const PageUniversityInformation = () => import('../../components/Page/University/UniversityInformation.vue' /* webpackChunkName: "components/page-university-information" */).then(c => wrapFunctional(c.default || c))
export const PageVacanciesResumeBanner = () => import('../../components/Page/Vacancies/ResumeBanner.vue' /* webpackChunkName: "components/page-vacancies-resume-banner" */).then(c => wrapFunctional(c.default || c))
export const PageVacanciesCard = () => import('../../components/Page/Vacancies/VacanciesCard.vue' /* webpackChunkName: "components/page-vacancies-card" */).then(c => wrapFunctional(c.default || c))
export const PageVacanciesVacancyFeedbackModal = () => import('../../components/Page/Vacancies/VacancyFeedbackModal.vue' /* webpackChunkName: "components/page-vacancies-vacancy-feedback-modal" */).then(c => wrapFunctional(c.default || c))
export const PageVacanciesVacancyModal = () => import('../../components/Page/Vacancies/VacancyModal.vue' /* webpackChunkName: "components/page-vacancies-vacancy-modal" */).then(c => wrapFunctional(c.default || c))
export const PageVacanciesVacancyResumeHiddenModal = () => import('../../components/Page/Vacancies/VacancyResumeHiddenModal.vue' /* webpackChunkName: "components/page-vacancies-vacancy-resume-hidden-modal" */).then(c => wrapFunctional(c.default || c))
export const PageWomenAPKBanner = () => import('../../components/Page/WomenAPK/WomenAPKBanner.vue' /* webpackChunkName: "components/page-women-a-p-k-banner" */).then(c => wrapFunctional(c.default || c))
export const PageWomenAPKBlock = () => import('../../components/Page/WomenAPK/WomenAPKBlock.vue' /* webpackChunkName: "components/page-women-a-p-k-block" */).then(c => wrapFunctional(c.default || c))
export const PageWomenAPKConditions = () => import('../../components/Page/WomenAPK/WomenAPKConditions.vue' /* webpackChunkName: "components/page-women-a-p-k-conditions" */).then(c => wrapFunctional(c.default || c))
export const PageWomenAPKContacts = () => import('../../components/Page/WomenAPK/WomenAPKContacts.vue' /* webpackChunkName: "components/page-women-a-p-k-contacts" */).then(c => wrapFunctional(c.default || c))
export const PageWomenAPKCriteria = () => import('../../components/Page/WomenAPK/WomenAPKCriteria.vue' /* webpackChunkName: "components/page-women-a-p-k-criteria" */).then(c => wrapFunctional(c.default || c))
export const PageWomenAPKForm = () => import('../../components/Page/WomenAPK/WomenAPKForm.vue' /* webpackChunkName: "components/page-women-a-p-k-form" */).then(c => wrapFunctional(c.default || c))
export const PageWomenAPKHandbook = () => import('../../components/Page/WomenAPK/WomenAPKHandbook.vue' /* webpackChunkName: "components/page-women-a-p-k-handbook" */).then(c => wrapFunctional(c.default || c))
export const PageWomenAPKInfo = () => import('../../components/Page/WomenAPK/WomenAPKInfo.vue' /* webpackChunkName: "components/page-women-a-p-k-info" */).then(c => wrapFunctional(c.default || c))
export const PageWomenAPKProject = () => import('../../components/Page/WomenAPK/WomenAPKProject.vue' /* webpackChunkName: "components/page-women-a-p-k-project" */).then(c => wrapFunctional(c.default || c))
export const PageWomenAPKRequirements = () => import('../../components/Page/WomenAPK/WomenAPKRequirements.vue' /* webpackChunkName: "components/page-women-a-p-k-requirements" */).then(c => wrapFunctional(c.default || c))
export const FiltersBlocksSelectDropdownList = () => import('../../components/Filters/Blocks/Select/DropdownList.vue' /* webpackChunkName: "components/filters-blocks-select-dropdown-list" */).then(c => wrapFunctional(c.default || c))
export const FiltersBlocksSelect = () => import('../../components/Filters/Blocks/Select/Index.vue' /* webpackChunkName: "components/filters-blocks-select" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
